import React, { useState, useEffect, useRef } from "react";
import "./EditProfile.css";
import Avatar from "@mui/material/Avatar";
import { db, storageRef, storage } from "../../firebase";
import { useSelector } from "react-redux";
import { selectUserAddress } from "../../features/userSlice";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const EditProfile = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [bio, setBio] = useState("");
  const [twitter, setTwitter] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");
  const useraddress = useSelector(selectUserAddress);
  const history = useHistory();
  const inputRef = useRef();
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const [userData, setUserData] = useState(null);
  const [dataName, setDataName] = useState("");
  const [loading, setLoading] = useState(false);
  const [firebaseImgUrl, setFirebaseImgUrl] = useState("");

  const handleFireBaseUpload = (userData) => {
    console.log("start of upload");
    // async magic goes here...
    const uploadTask = storage.ref(`/images/${useraddress}`).put(imageAsFile);

    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        // console.log(snapShot);
      },
      (err) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:

        storageRef
          .child(`/images/${useraddress}`)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            {
              imageAsFile !== "" &&
                db.collection("userProfile").doc(useraddress).update({
                  Image: fireBaseUrl,
                });
            }
          });
      }
    );
  };

  const fetchData = async () => {
    // console.log({ useraddress });
    const response = db.collection("userProfile").doc(useraddress);
    response
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          setUserData(doc.data());
          // console.log(doc.data());
          // console.log(userData);
        } else {
          console.log("No such doc");
        }
      })
      .catch((error) => {
        console.log("Error getting doc", error);
      });
  };
  if (useraddress && !userData) {
    fetchData();
    // getUserNFTs();
  }

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleUrl = (e) => {
    setUrl(e.target.value);
  };
  const handleBio = (e) => {
    setBio(e.target.value);
  };
  const handleTwitter = (e) => {
    setTwitter(e.target.value);
  };
  const handleSite = (e) => {
    setSite(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleEdit = () => {
    {
      name !== "" &&
        db.collection("userProfile").doc(useraddress).update({ Name: name });
    }
    {
      bio !== "" &&
        db.collection("userProfile").doc(useraddress).update({ Bio: bio });
    }
    {
      email !== "" &&
        db.collection("userProfile").doc(useraddress).update({ Email: email });
    }
    {
      twitter !== "" &&
        db
          .collection("userProfile")
          .doc(useraddress)
          .update({ TwitterUsername: twitter });
    }
    {
      url !== "" &&
        db
          .collection("userProfile")
          .doc(useraddress)
          .update({ CustomUrl: url });
    }
    {
      site !== "" &&
        db
          .collection("userProfile")
          .doc(useraddress)
          .update({ PersonalSite: site });
    }
    {
      imageAsFile !== "" && handleFireBaseUpload();
    }
    alert("Your profile is updated");
    history.push("/");
  };

  return (
    <div style={{ backgroundColor: "white" }} className="editProfile">
      <div className="editProfile__container1">
        <h1 style={{ color: "black" }} className="editProfile__text">
          Edit Profile
        </h1>
        <div className="form">
          <div className="form__content">
            <h4 style={{ color: "black" }} className="form__text">
              Display Name
            </h4>
            <input
              style={{ backgroundColor: "#e6e6e6", color: "black" }}
              placeholder={userData?.Name}
              value={name}
              onChange={handleName}
              className="form__input"
            />
          </div>
          <div className="form__content">
            <h4 style={{ color: "black" }} className="form__text">
              Custom Url
            </h4>
            <input
              style={{ backgroundColor: "#e6e6e6", color: "black" }}
              placeholder={userData?.CustomUrl}
              value={url}
              onChange={handleUrl}
              className="form__input"
            />
          </div>
          <div className="form__content">
            <h4 style={{ color: "black" }} className="form__text">
              Bio
            </h4>
            <input
              style={{ backgroundColor: "#e6e6e6", color: "black" }}
              placeholder={userData?.Bio}
              value={bio}
              onChange={handleBio}
              className="form__input"
            />
          </div>
          <div className="form__content">
            <h4 style={{ color: "black" }} className="form__text">
              Twitter Username
            </h4>
            <input
              style={{ backgroundColor: "#e6e6e6", color: "black" }}
              placeholder={userData?.TwitterUsername}
              value={twitter}
              onChange={handleTwitter}
              className="form__input"
            />
          </div>
          <div className="form__content">
            <h4 style={{ color: "black" }} className="form__text">
              Personal Site or Portfolio
            </h4>
            <input
              style={{ backgroundColor: "#e6e6e6", color: "black" }}
              placeholder={userData?.PersonalSite}
              value={site}
              onChange={handleSite}
              className="form__input"
            />
          </div>
          <div className="form__content">
            <h4 style={{ color: "black" }} className="form__text">
              Email
            </h4>
            <input
              style={{ backgroundColor: "#e6e6e6", color: "black" }}
              placeholder={userData?.Email}
              value={email}
              onChange={handleEmail}
              className="form__input"
            />
          </div>
          <div className="form__verification">
            {!useraddress ? (
              <button
                onClick={() => alert("Sign In with MetaMask")}
                className="editProfile__button"
              >
                Save Profile
              </button>
            ) : (
              <button onClick={handleEdit} className="editProfile__button">
                Save Profile
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="editProfile__container2">
        <Avatar
          alt=""
          src={imageAsFile ? URL.createObjectURL(imageAsFile) : userData?.Image}
          sx={{ width: 250, height: 250 }}
        />
        <input
          className="img__input"
          onChange={handleImageAsFile}
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
        />
        <button
          onClick={() => {
            inputRef.current.click();
          }}
          className="profile__button"
        >
          Change profile picture
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
