import React, { createRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import ExploreOne from "../themes/explore-one";
import ExploreTwo from "../themes/explore-two";
import ExploreThree from "../themes/explore-three";
import ExploreFour from "../themes/explore-four";
import Auctions from "../themes/auctions";
import ItemDetails from "../themes/item-details";
import Activity from "../themes/activity";
import Blog from "../themes/blog";
import BlogSingle from "../themes/blog-single";
import HelpCenter from "../themes/help-center";
import Authors from "../themes/authors";
import Author from "../themes/author";
import WalletConnect from "../themes/wallet-connect";
import Create from "../themes/create";
import Contact from "../themes/contact";
import Sale from "../themes/sale";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import Userprofile from "../themes/userprofile";
import Editprofile from "../themes/edit-profile";
import Register from "../components/Register/Register";
import Registration from "../themes/register";
import AllSale from "../components/All SaLe/AllSale";
import allSale from "../themes/allSale";
import axios from "axios";

let history;
class MyRouts extends React.Component {
  state = { data: [] };

  constructor(props) {
    super(props);
    this.modalRef = createRef();
  }

  getAllNft = async () => {
    const query = `
    {
        nftentities {
          name
          description
          id
          uri
          owner
          creator {
            id
          }
          sale {
            id
            price
          }
          auction {
            id
          }
        }
      }
    `;
    try {
      const res = await axios.post(
        "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
        {
          query,
        }
      );

      if (this._isMounted === 1) {
        this.setState({
          isLoaded: true,
          data: res.data.data.nftentities,
        });
      }
    } catch (error) {
      this.setState(() => ({ error }));
      console.log(error);
    }
  };
  // componentWillMount() {
  //   console.log("working");
  //   this.getAllNft();
  // }

  state = { address: null };

  closeModalSearch = () => {
    this.modalRef.current.click();
  };

  updateAddress = (addr) => {
    this.setState({
      address: addr,
    });
  };

  render() {
    return (
      <div>
        <Router>
          <Header modalRef={this.modalRef} updateAddress={this.updateAddress} />
          <ModalSearch closeModalSearch={this.closeModalSearch} />

          <Switch>
            <Route exact path="/" component={ThemeOne} />
            <Route
              exact
              path="/details/:id"
              component={(props) => (
                <ItemDetails
                  {...props}
                  address={this.state.address}
                  hello={"hello"}
                />
              )}
            />
            {/* <Route exact path='/item-details/:id' component={Sale} /> */}
            <Route exact path="/explore-1" component={ExploreOne} />
            <Route exact path="/explore-2" component={ExploreTwo} />
            <Route exact path="/explore-3" component={ExploreThree} />
            <Route exact path="/explore-4" component={ExploreFour} />
            <Route exact path="/auctions" component={Auctions} />
            <Route exact path="/user-profile" component={Userprofile} />
            <Route exact path="/edit-profile" component={Editprofile} />
            <Route exact path="/registration" component={Registration} />
            <Route exact path="/allSaLe" component={allSale} />

            {/* <Route exact path="/item-details" component={ItemDetails} /> */}
            <Route exact path="/activity" component={Activity} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog-single" component={BlogSingle} />
            <Route exact path="/help-center" component={HelpCenter} />
            <Route exact path="/authors" component={Authors} />
            <Route exact path="/author" component={Author} />
            <Route exact path="/creator/:id" component={Author} />
            <Route exact path="/wallet-connect" component={WalletConnect} />
            <Route exact path="/create" component={Create} />
            {/* <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} /> */}
            <Route exact path="/contact" component={Contact} />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}
export default MyRouts;
