import React, { Component } from "react";
import axios from "axios";
import { gql } from "@apollo/client";
import { withRouter } from "react-router";
import { db } from "../../firebase";
import CircularProgress from "@mui/material/CircularProgress";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json-1/authors";

let user = [];

class Authors extends Component {
  _isMounted = 1;

  state = {
    data: [],
    authorData: [],
    creatorData: [],
    error: null,
    nftOwner: [],
    userData: [],
    loading: true,
  };
  loadMedia = (src) => {
    var img = new Image();
    img.onerror = () => {
      this.setState({ ...this.state, vid: src });
    };
    img.onload = () => {
      this.setState({ ...this.state, img: src });
    };
    img.src = src;
  };
  fetchImageObject = async (uri) => {
    try {
      axios
        .get(`https://sainft.infura-ipfs.io/ipfs/${uri}`)
        .then((resp) =>
          this.loadMedia(
            `https://sainft.infura-ipfs.io/ipfs/${resp.data.image?.split("ipfs://")[1]}`
          )
        );
    } catch (error) {
      console.log(error);
    }
  };
  getAllCreator = gql`
    {
      creators {
        id
        nfts {
          id
        }
      }
    }
  `;
  getAllCreator = async (query) => {
    try {
      const response = await axios.post(
        "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
        {
          query,
        }
      );
      if (this._isMounted === 1) {
        this.setState(() => ({
          creatorData: response.data.data.creators,
          nftOwner: response.data.data.creators.id,
        }));
      }
      this.fetchUserData();
    } catch (error) {
      this.setState(() => ({ error }));
      console.log(error);
    }
  };

  fetchUserData = async () => {
    function addItem(item) {
      var index = user.findIndex((x) => x.Name == item.Name);
      if (index === -1) {
        user.push(item);
      }
    }
    {
      this.state.creatorData.map((data) => {
        const response = db.collection("userProfile").doc(data.id);
        response
          .get()
          .then((doc) => {
            addItem(doc.data());
            if (doc.exists) {
              this.setState({ loading: true });
              this.setState({ userData: doc.data() });
              this.setState({ loading: false });
            } else {
              console.log("No such doc");
            }
          })
          .catch((error) => {
            console.log("Error getting doc", error);
          });
      });
    }
    return this.state.userData;
  };

  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          authorData: res.data.authorData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
    this._isMounted = 1;
    const query = `
    {
        creators {
          id
          nfts {
            id
          }
        }
      }
    `;
    this.getAllCreator(query);

    const path = window.location.pathname;
    const id = path.split("/")[2];
    // this.getNftOfCreator(id);
  }
  componentWillUnmount() {
    this._isMounted = 0;
  }
  render() {
    var { userData } = this.state;
    var { creatorData } = this.state;

    return (
      <section
        style={{
          backgroundColor: "white",
          paddingTop: "120px",
          paddingBottom: "130px",
          backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)",
        }}
        className="popular-collections-area"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              {/* Intro */}
              <div className="intro text-center">
                <span>{this.state.data.preHeading}</span>
                <h3 style={{ color: "black" }} className="mt-3 mb-0">
                  Our Creators
                </h3>
                <div style={{ marginTop: "50px" }}>
                  {this.state.loading && <CircularProgress color="inherit" />}
                </div>
              </div>
            </div>
          </div>
          <div className="row items">
            {user.map((data, idx) => {
              return (
                <div
                  key={`ad_${idx}`}
                  className="col-12 col-sm-6 col-lg-3 item"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.props.history.push(`/creator/${data?.MetamaskAddress}`)
                  }
                >
                  <div className="card no-hover text-center">
                    <div className="image-over">
                      {/* Seller */}
                      <a className="seller">
                        <div className="seller-thumb avatar-lg">
                          <img
                            className="rounded-circle"
                            alt=""
                            src={data?.Image || "/img/auction_2.jpg"}
                          />
                        </div>
                      </a>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                      {/* Card Body */}
                      <div className="card-body mt-4">
                        <h5>{data?.Name}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Authors);
