import React, { Component, useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import axios from "axios";
import { withRouter } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";

const initData = {
  pre_heading: "Explore",
  heading: "Exclusive Digital Assets",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
  btnText: "Load More",
};
const NftView = ({ src }) => {
  const [img, setImg] = useState(true);
  const loadMedia = (src) => {
    var img = new Image();
    img.onerror = () => {
      console.log(src);
      setImg(false);
    };
    img.src = src;
  };
  useEffect(() => {
    loadMedia(src);
  }, []);
  if (img) {
    return <img className="card-img-top" src={src} alt="" />;
  }
  return (
    <video
      autoPlay
      loop
      muted
      className="card-img-top"
      src={src}
      alt=""
    ></video>
  );
};

const NftImg = ({ src }) => {
  const [img, setImg] = useState(true);
  const loadMedia = (src) => {
    var img = new Image();
    img.onerror = () => {
      console.log(src);
      setImg(false);
    };
    img.src = src;
  };
  useEffect(() => {
    loadMedia(src);
  }, []);

  return (
    <img
      style={{ height: "auto", width: "300px" }}
      className="card-img-top"
      src={src}
      alt=""
    />
  );
};
class ExploreFour extends Component {
  _isMounted = 1;
  constructor(props) {
    super(props);
    this.getAllNft();
    this.explore = React.createRef();
  }

  state = {
    initData: {},
    data: [],
    ImgUri: [],
    error: null,
    loading: true,
    source: [],
    videoSrc: "",
    image: true,
    vid: true,
    imageUri: {},
    currentLoad: 0,
  };
  getAllNft = gql`
    {
      nftentities {
        name
        description
        id
        uri
        owner
        creator {
          id
        }
        sale {
          id
          price
        }
        auction {
          id
        }
      }
    }
  `;
  fetchImageObject = async () => {
    try {
      if (this._isMounted === 1) {
        this.state.data.forEach((nft) =>
          axios
            .get(`https://sainft.infura-ipfs.io/ipfs/${nft.uri}`)
            .then((resp) =>
              this.setState({
                source: {
                  ...this.state.source,
                  [nft.id]: `https://sainft.infura-ipfs.io/ipfs/${resp.data.url}`,
                },
              })
            )
        );
      }

      if (this._isMounted === 1) {
        this.setState(() => ({
          isLoaded: true,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  getAllNft = async () => {
    const query = `
    {
        nftentities {
          name
          description
          id
          uri
          owner
          creator {
            id
          }
          sale {
            id
            price
          }
          auction {
            id
          }
        }
      }
    `;
    try {
      const res = await axios.post(
        "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
        {
          query,
        }
      );

      if (this._isMounted === 1) {
        this.setState({ loading: true });

        this.setState(
          {
            isLoaded: true,
            data: res.data.data.nftentities,
            currentLoad:
              res.data.data.nftentities.length > 8
                ? 8
                : res.data.data.nftentities.length,
          },
          () => {
            this.fetchImageObject();
          }
        );
        // console.log(this.state.data);
      }
    } catch (error) {
      this.setState(() => ({ error }));
      console.log(error);
    }
  };

  componentDidMount() {
    // this.fetchImageObject();

    if (this._isMounted === 1) {
      this.setState({
        initData: initData,
        // data: data,
        // source: source,
      });
    }

    this._isMounted = 1;
    // this.getAllNft();
    document.addEventListener("scroll", this.trackScroll);
  }
  trackScroll = () => {
    if (
      this.explore.current.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      // console.log("We reached the bottom");

      this.handleLoadMore();

      if (this.state.currentLoad === this.state.data.length) {
        document.removeEventListener("scroll", this.trackScroll);
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.data.length !== this.state.data.length) {
      document.addEventListener("scroll", this.trackScroll);
    }
  }
  componentWillUnmount() {
    this._isMounted = 0;
    this.setState = (state, callback) => {
      return;
    };
    document.removeEventListener("scroll", this.trackScroll);
  }
  handleLoadMore = () => {
    if (this.state.currentLoad < this.state.data.length) {
      if (this.state.currentLoad + 8 <= this.state.data.length) {
        this.setState({ loading: true });

        this.setState({ currentLoad: this.state.currentLoad + 8 });
        this.setState({ loading: true });
      } else {
        this.setState({ loading: true });

        this.setState({ currentLoad: this.state.data.length });
        this.setState({ loading: false });
      }
    } else {
      console.log("print");
    }
  };
  render() {
    const { currentLoad } = this.state;
    return (
      <section
        style={{ backgroundColor: "white", height: "100%" ,backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)",}}
        className="explore-area load-more"
        ref={this.explore}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              {/* Intro */}
              <div className="intro text-center">
                <span>{this.state.initData.pre_heading}</span>
                <h3 style={{ color: "black" }} className="mt-3 mb-0">
                  {this.state.initData.heading}
                </h3>
              </div>
            </div>
          </div>
          {/* <InfiniteScroll dataLength={this.state.data.length}> */}
          <div className="row items">
            {this.state.data.slice(0, currentLoad).map((item, idx) => {
              return (
                <div
                  key={`exf_${idx}`}
                  onClick={(e) =>
                    this.props.history.push(
                      `/details/${item.id}`,
                      this.state.data
                    )
                  }
                  className="col-12 col-sm-6 col-lg-3 mt-4"
                >
                  <div
                    style={{
                      height: "fit-content",
                      cursor: "pointer",
                      width: "274px",
                    }}
                    className="card"
                  >
                    {this._isMounted === 1 ? (
                      <div style={{ height: "180px" }} className="image-over">
                        <a>
                          <div
                            style={{
                              backgroundImage: `url(${
                                this.state.source[item.id]
                              })`,
                              height: "180px",
                              width: "100%",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                          ></div>
                          {/* <NftImg src={this.state.source[idx]} /> */}
                        </a>
                      </div>
                    ) : (
                      <span></span>
                    )}
                    <div className="image-over">
                      <img className="card-img-top" src={item.img} alt="" />
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                      {/* Card Body */}
                      <div className="card-body">
                        <h5 className="mb-0">{item.name}</h5>
                        <div className="seller d-flex align-items-center my-3">
                          <span>Owned By</span>
                          <a href="/author">{/* <h6>{item.owner}</h6> */}</a>
                        </div>
                        <div className="card-bottom d-flex justify-content-between">
                          <span style={{ fontSize: "9px", fontWeight: "bold" }}>
                            {item.owner}
                          </span>
                          {/* <span>{item.count}</span> */}
                        </div>
                        {(!item?.sale && item?.auction && (
                          <a
                            className="btn btn-bordered-white btn-smaller mt-3"
                            onClick={() =>
                              this.props.history.push(
                                `/details/${item.id}`,
                                this.state.data
                              )
                            }
                          >
                            Bid
                          </a>
                        )) ||
                          (!item?.auction && item?.sale && (
                            <a
                              className="btn btn-bordered-white btn-smaller mt-3"
                              onClick={() =>
                                this.props.history.push(
                                  `/details/${item.id}`,
                                  this.state.data
                                )
                              }
                            >
                              Buy
                            </a>
                          )) ||
                          (!item?.sale && !item?.auction && (
                            <a
                              className="btn btn-bordered-white btn-smaller mt-3"
                              onClick={() =>
                                this.props.history.push(
                                  `/details/${item.id}`,
                                  this.state.data
                                )
                              }
                            >
                              View
                            </a>
                          )) ||
                          null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* </InfiniteScroll> */}
          <div style={{ marginTop: "30px" }} className="row">
            <div className="col-12 text-center">
              {this.state.loading && <CircularProgress color="inherit" />}
            </div>
          </div>

          {/* <div className="row">
            <div className="col-12 text-center">
              {currentLoad === this.state.data.length ? null : (
                <span onClick={this.handleLoadMore} className="btn btn mt-5">
                  {this.state.initData.btnText}
                </span>
              )}
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

export default withRouter(ExploreFour);
