import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import Web3 from "web3";
import { ESCROW_CONTRACT_ADDRESS } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";

// const BASE_URL =
// 'https://my-json-server.typicode.com/themeland/netstorm-json-1/activity'

// --------------------------

const NftImg = ({ uri }) => {
  const [img, setImg] = useState();
  const [src, setSrc] = useState("");
  const loadMedia = (src) => {
    setImg(true);
    var img = new Image();
    img.onerror = () => {
      setImg(false);
    };
    img.src = src;
  };

  const fetchImageObject = async (uri) => {
    try {
      axios.get(`https://sainft.infura-ipfs.io/ipfs/${uri}`).then((resp) => {
        const url = `https://sainft.infura-ipfs.io/ipfs/${resp.data.url}`;
        setSrc(url);
        loadMedia(url);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchImageObject(uri);
  }, []);
  if (img) {
    return (
      <img
        className="card-img-top"
        style={{
          height: "80px",
          width: "80px",
          borderRadius: "50%",
          verticalAlign: "middle",
        }}
        src={src}
        alt=""
      />
    );
  }

  return (
    <video
      autoPlay
      loop
      muted
      className="card-img-top"
      src={src}
      alt=""
    ></video>
  );
};

const mystyle = {
  maxHeight: "80px",
  maxWidth: "80px",
  borderRadius: "50%",
  verticalAlign: "middle",
};

// -------------

class Activity extends Component {
  state = {
    data: [],
    ImgUri: [],
    source: [],
    videoSrc: "",
    error: null,
    isLoaded: false,
    image: true,
    vid: true,
    imageUri: {},
    loading: true,
    // filterData: [],
  };

  loadMedia = (src) => {
    var img = new Image();
    img.onerror = () => {
      console.log(src);
      this.setState({ ...this.state, vid: src });
    };
    img.onload = () => {
      this.setState({ ...this.state, img: src });
    };
    img.src = src;
  };

  // getAuctionById = async (id) => {
  //     const response =
  // }

  // after auctions word
  // (where: {token:"${
  //                 '0x' + id
  //               }"},
  //               orderBy: timestamp, orderDirection: desc)

  async componentDidMount() {
    const res = await axios.post(
      "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
      {
        query: `{
  nftentities (orderBy: id, orderDirection: desc) {
    name
    id
    owner
    description
    uri
    sale {
      id
      price
      owner
      buyer
      saleCreatedAt
      saleEndedAt
    }
    auction {
      id
      token {
        id
        name
      }
      reservePrice
      owner
      lastBid {
        id
        bid
        bidder
        timestamp
      }
    }
    creator {
      id
    }
  }
}

              `,
      }
    );

    try {
      this.setState({ loading: true });
      this.setState({
        data: res.data.data.nftentities,
      });
      this.setState({ loading: false });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <section
        style={{ backgroundColor: "white",backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)", }}
        className="activity-area load-more"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro mb-4">
                <div className="intro-content">
                  <span>CREATIVE</span>
                  <h3 style={{ color: "black" }} className="mt-3 mb-0">
                    Activity
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row items">
            <div className="col-12 col-md-6 col-lg-8">
              {/* Netstorm Tab */}
              <ul
                style={{ margin: "10px" }}
                className="netstorm-tab nav"
                id="nav-tab"
              >
                <li>
                  <a
                    className="active"
                    id="nav-home-tab"
                    data-toggle="pill"
                    href="#nav-home"
                  >
                    <h5 style={{ color: "black" }} className="m-0">
                      All
                    </h5>
                  </a>
                </li>
                <li>
                  <a
                    id="nav-profile-tab"
                    data-toggle="pill"
                    href="#nav-profile"
                  >
                    <h5 style={{ color: "black" }} className="m-0">
                      Purchase
                    </h5>
                  </a>
                </li>
                <li>
                  <a
                    id="nav-contact-tab"
                    data-toggle="pill"
                    href="#nav-contact"
                  >
                    <h5 style={{ color: "black" }} className="m-0">
                      Auction
                    </h5>
                  </a>
                </li>
              </ul>
              {/* Tab Content */}
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home">
                  <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    {this.state.loading && <CircularProgress color="inherit" />}
                  </div>

                  <ul className="list-unstyled">
                    {/* Single Tab List */}
                    {this.state.data.map((item, idx) => {
                      return (
                        <li
                          key={`ato_${idx}`}
                          className="single-tab-list d-flex align-items-center"
                        >
                          <a href={`/details/${item.id}`}>
                            {/* <img
                                className='avatar-lg'
                                src={
                                  '/static/images/avatar/1.jpg'
                                }
                                alt=''
                              /> */}
                            <NftImg uri={item.uri} />
                          </a>
                          {/* Activity Content */}
                          <div className="activity-content ml-4">
                            <a href={`/details/${item.id}`}>
                              <h5
                                style={{ color: "black" }}
                                className=" all-history "
                              >
                                {item.name}
                              </h5>
                            </a>
                            <p className=" all-history">
                              Created by{" "}
                              {/* <strong>
                                  {
                                    item.auction
                                      ?.lastBid
                                      ?.bid
                                  }
                                </strong>{' '}
                                {
                                  item.auction
                                    ?.lastBid
                                    ?.timestamp
                                }{' '} */}
                              <br />{" "}
                              <a
                                className="all-history"
                                style={{ color: "black" }}
                                href={`/creator/${item.creator.id}`}
                              >
                                {item.creator.id}
                              </a>
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="tab-pane fade" id="nav-profile">
                  <ul className="list-unstyled">
                    {/* Single Tab List */}
                    {this.state.data
                      .filter((nft) => nft.sale !== null)
                      .map((item, idx) => {
                        return (
                          <li
                            key={`ato_${idx}`}
                            className="single-tab-list d-flex align-items-center"
                          >
                            <a href={`/details/${item.id}`}>
                              {/* <img
                                className='avatar-lg'
                                src={
                                  '/static/images/avatar/1.jpg'
                                }
                                alt=''
                              /> */}
                              <NftImg uri={item.uri} />
                            </a>
                            {/* Activity Content */}
                            <div className="activity-content ml-4">
                              <a href={`/details/${item.id}`}>
                                <h5
                                  style={{ color: "black" }}
                                  className="mt-0 mb-2"
                                >
                                  {item.name}
                                </h5>
                              </a>
                              {item.owner == ESCROW_CONTRACT_ADDRESS ? (
                                <p className="m-0">
                                  Purchased at price{" "}
                                  {Web3.utils.fromWei(item.sale?.price)}
                                  {" MATIC"}
                                  {/* {
                                  item.sale
                                    ?.saleCreatedAt
                                }{' '} */}
                                  <br />
                                  by{" "}
                                  <a style={{ color: "black" }}>{item.owner}</a>
                                  Creator{" "}
                                  <a
                                    style={{ color: "black" }}
                                    href={`/creator/${item.creator.id}`}
                                  >
                                    {item.creator.id}
                                  </a>
                                </p>
                              ) : (
                                <p className="m-0">
                                  On Sale For{" "}
                                  {Web3.utils.fromWei(item.sale?.price)}
                                  {" MATIC"}
                                  {/* {
                                item.sale
                                  ?.saleCreatedAt
                              }{' '} */}
                                  <br />
                                  by{" "}
                                  <a style={{ color: "black" }}>
                                    {item.sale?.owner}
                                  </a>
                                  Creator{" "}
                                  <a
                                    style={{ color: "black" }}
                                    href={`/creator/${item.creator.id}`}
                                  >
                                    {item.creator.id}
                                  </a>
                                </p>
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="tab-pane fade" id="nav-contact">
                  <ul className="list-unstyled">
                    {/* Single Tab List */}
                    {this.state.data
                      .filter((nft) => nft.auction !== null)
                      .map((item, idx) => {
                        return (
                          <li
                            key={`ato_${idx}`}
                            className="single-tab-list d-flex align-items-center"
                          >
                            <a href={`/details/${item.id}`}>
                              {/* <img
                                className='avatar-lg'
                                src={
                                  '/static/images/avatar/1.jpg'
                                }
                                alt=''
                              /> */}
                              <NftImg uri={item.uri} />
                            </a>
                            {/* Activity Content */}
                            <div className="activity-content ml-4">
                              <a href={`/details/${item.id}`}>
                                <h5
                                  style={{ color: "black" }}
                                  className="mt-0 mb-2"
                                >
                                  {item.name}
                                </h5>
                              </a>
                              {item.auction?.lastBid?.bid ? (
                                <p className="m-0">
                                  Bid listed for{" "}
                                  {Web3.utils.fromWei(
                                    item.auction?.lastBid?.bid
                                  )}
                                  {" MATIC"}
                                  {/* {
                                  item.sale
                                    ?.saleEndedAt
                                }{' '} */}
                                  <br />
                                  by{" "}
                                  <a
                                    style={{ color: "black" }}
                                    href={`/creator/${item.auction.owner}`}
                                  >
                                    {item.auction?.lastBid?.bidder}
                                  </a>
                                  Reserve Price{" "}
                                  {Web3.utils.fromWei(
                                    item.auction.reservePrice
                                  )}
                                  {" MATIC"}
                                </p>
                              ) : (
                                <span>No Bids Yet</span>
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            {/*  <div className='col-12 col-md-6 col-lg-4'>
              
              <div className='activity-content mt-5 mt-lg-0'>
              
                <div className='single-widget'>
                 
                  <div className='widget-content search-widget'>
                    <form action='#'>
                      <input
                        type='text'
                        placeholder='Enter your keywords'
                      />
                    </form>
                  </div>
                </div>
                
                <div className='single-widget'>
                  
                  <div className='widget filter-widget'>
                    <h4 className='title'>
                      {
                        this.state.data
                          .widgetTitle
                      }
                    </h4>
                
                    <div className='widget-content'>
                    
                      <div className='widget-content filter-widget-items mt-3'>
                        {this.state.filterData.map(
                          (item, idx) => {
                            return {
                              
                            }
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
            </div>
            */}
          </div>
        </div>
      </section>
    );
  }
}

export default Activity;
