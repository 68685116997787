import React, { Component } from "react";
import axios from "axios";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json/work";

class Work extends Component {
  state = {
    data: {},
    workData: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          workData: res.data.workData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <section className="work-area" style={{backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)"}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro mb-4">
                <div className="intro-content">
                  <span>{this.state.data.preHeading}</span>
                  <h3 style={{ color: "black" }} className="mt-3 mb-0">
                    {this.state.data.heading}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row items">
            {this.state.workData.map((item, idx) => {
              return (
                <div
                  key={`wd_${idx}`}
                  className="col-12 col-sm-6 col-lg-3 item"
                >
                  {/* Single Work */}
                  <div className="single-work">
                    <i className={item.icon} />
                    <h4 style={{ color: "black" }}>{item.title}</h4>
                    <p style={{
                      color:"black",
                      background: "-webkit-linear-gradient(#4B0082, #F9629F)",
                      webkitBackgroundClip: "text",
                      webkitTextFillColor: "transparent"}}>{item.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Work;
