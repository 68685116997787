import React, { Component } from "react";
import AuthorProfile from "../AuthorProfile/AuthorProfile";
import Explore from "../Explore/ExploreSix";

class Author extends Component {
  render() {
    return (
      <section
        style={{ backgroundColor: "white" ,backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)",}}
        className="mt-5 author-area explore-area popular-collections-area"
      >
        <div className="container">
          <div className="row justify-content-between">
            <div style={{ margin: "10px" }} className="col-12 col-md-12">
              {/* Author Profile */}
              <AuthorProfile />
            </div>
            <div className="col-12 col-md-12  ">
              <Explore />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Author;
