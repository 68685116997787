import React, { useEffect } from "react";
import ApolloClient from "apollo-boost";
// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";

function App() {
  useEffect(() => {}, []);
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;
