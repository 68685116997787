import React, { useState, useEffect, useRef } from "react";
import "./Register.css";
import Avatar from "@mui/material/Avatar";
import { db, storageRef, storage } from "../../firebase";
import { useSelector } from "react-redux";
import { selectUserAddress } from "../../features/userSlice";
import { useHistory } from "react-router-dom";

const Register = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [bio, setBio] = useState("");
  const [twitter, setTwitter] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");
  const useraddress = useSelector(selectUserAddress);
  const history = useHistory();
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);

  const inputRef = useRef(null);
  const handleFireBaseUpload = () => {
    console.log("start of upload");
    // async magic goes here...
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    const uploadTask = storage.ref(`/images/${useraddress}`).put(imageAsFile);

    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        // console.log(snapShot);
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:

        storageRef
          .child(`/images/${useraddress}`)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log(fireBaseUrl);
            db.collection("userProfile").doc(useraddress).set(
              {
                MetamaskAddress: useraddress,
                Name: name,
                Bio: bio,
                Email: email,
                TwitterUsername: twitter,
                PersonalSite: site,
                CustomUrl: url,
                Image: fireBaseUrl,
              },
              { merge: true }
            );
            history.push("/");
            alert("Thankyou for registration");
          });
      }
    );
  };
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleUrl = (e) => {
    setUrl(e.target.value);
  };
  const handleBio = (e) => {
    setBio(e.target.value);
  };
  const handleTwitter = (e) => {
    setTwitter(e.target.value);
  };
  const handleSite = (e) => {
    setSite(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    console.log(useraddress);
  }, []);

  const handleSubmit = () => {
    handleFireBaseUpload();
  };

  return (
    <div style={{ backgroundColor: "white" }} className="editProfile">
      {useraddress && (
        <>
          <div className="editProfile__container1">
            <h1 style={{ color: "black" }} className="editProfile__text">
              Registration
            </h1>
            <div className="form">
              <form>
                <div className="form__content">
                  <h4 style={{ color: "black" }} className="form__text">
                    Display Name
                  </h4>
                  <input
                    style={{ backgroundColor: "#e6e6e6", color: "black" }}
                    value={name}
                    onChange={handleName}
                    className="form__input"
                    required
                  />
                </div>

                <div className="form__content">
                  <h4 style={{ color: "black" }} className="form__text">
                    Bio
                  </h4>
                  <input
                    style={{ backgroundColor: "#e6e6e6", color: "black" }}
                    value={bio}
                    onChange={handleBio}
                    className="form__input"
                    required
                  />
                </div>

                <div className="form__content">
                  <h4 style={{ color: "black" }} className="form__text">
                    Email
                  </h4>
                  <input
                    style={{ backgroundColor: "#e6e6e6", color: "black" }}
                    value={email}
                    onChange={handleEmail}
                    className="form__input"
                    required
                  />
                </div>
                <div className="form__content">
                  <h4 style={{ color: "black" }} className="form__text">
                    Twitter Username
                  </h4>
                  <input
                    style={{ backgroundColor: "#e6e6e6", color: "black" }}
                    value={twitter}
                    onChange={handleTwitter}
                    className="form__input"
                    required
                  />
                </div>
                <div className="form__content">
                  <h4 style={{ color: "black" }} className="form__text">
                    Custom Url
                  </h4>
                  <input
                    style={{ backgroundColor: "#e6e6e6", color: "black" }}
                    value={url}
                    onChange={handleUrl}
                    className="form__input"
                    required
                  />
                </div>
                <div className="form__content">
                  <h4 style={{ color: "black" }} className="form__text">
                    Personal Site or Portfolio
                  </h4>
                  <input
                    style={{ backgroundColor: "#e6e6e6", color: "black" }}
                    value={site}
                    onChange={handleSite}
                    className="form__input"
                    required
                  />
                </div>
              </form>

              <div className="form__verification">
                <button onClick={handleSubmit} className="editProfile__button">
                  Register
                </button>
              </div>
            </div>
          </div>
          <div className="editProfile__container2">
            <Avatar
              alt="image tag"
              src={imageAsFile ? URL.createObjectURL(imageAsFile) : null}
              className="avatar__img"
              sx={{ width: 250, height: 250 }}
            />

            <input
              className="img__input"
              onChange={handleImageAsFile}
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
            />
            <button
              onClick={() => {
                inputRef.current.click();
              }}
              onSubmit={handleFireBaseUpload}
              className="profile__button"
            >
              Add profile picture
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
