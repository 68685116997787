import React, { useState, useEffect } from 'react'
import AuthorProfile from '../AuthorProfile/AuthorProfile'
import axios from 'axios'
import Web3 from 'web3'
import { CONTRACT_ABI, CONTRACT_ADDRESS } from '../../config'
import { db } from '../../firebase'
import { useSelector } from 'react-redux'
import { selectUserAddress } from '../../features/userSlice'

function Create () {
  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState()
  const [isCreating, setIsCreating] = useState()
  const [err, setErr] = useState()
  const [userData, setUserData] = useState(null)
  const useraddress = useSelector(selectUserAddress)

  const fetchData = async () => {
    const response = db.collection('userProfile').doc(useraddress)
    response
      .get()
      .then(doc => {
        if (doc.exists) {
          setUserData(doc.data())
        } else {
          console.log('No such doc')
        }
      })
      .catch(error => {
        console.log('Error getting doc', error)
      })
  }
  if (useraddress && !userData) {
    fetchData()
  }
  useEffect(() => {
    fetchData()
  }, [useraddress])
  const changeHandler = event => {
    console.log('Inside Change Handler')
    let files = event.target.files

    setIsFilePicked(true)
    setSelectedFile(files[0])
  }
  const web3 = new Web3(window.ethereum)

  const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)

  const mintImage = async (nftName, descript, uri) => {
    let account
    if (typeof window.ethereum !== 'undefined') {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      // const accounts = await web3.eth.getAccounts();
      account = accounts[0]
    } else {
      alert(
        "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
      )
    }

    await nftContract.methods
      .mintNFT(account, uri, nftName, descript)
      .send({
        from: account
        // gas: 23000
      })
      .once('transactionHash', function () {
        console.log('Transaction Processing............')
      })
      .once('receipt', function () {
        console.log('Ethereum Reciept')
      })
      .once('confirmation', function () {
        console.log('Transaction Confirmed')
        alert(`Your NFT ${nftName} Has Been Created!`)
        window.location.reload();
      })
      .once('error', async function () {
        alert('Ethereum Error Encountered!')
        window.location.reload()
      })

    setIsCreating(false)
  }

  const onSubmit = async e => {
    e.preventDefault()
    setIsCreating(true)

    const formData = new FormData()
    formData.append('file', selectedFile)

    try {
      const name = document.getElementById('name').value
      const description = document.getElementById('textarea').value

      const imageHash = await axios.post(
        'https://sainftbackend.onrender.com/api/uploadImage',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      const payload = {
        nftName: name,
        descript: description,
        imgHash: imageHash.data.data
      }

      const fileHash = await axios.post(
        'https://sainftbackend.onrender.com/api/createJson',
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await mintImage(name, description, fileHash.data.data)
    } catch (error) {
      setErr(error)
      console.log('error', error)
    }
  }

  return (
    <section style={{ backgroundColor: 'white',backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)", }} className='mt-4 author-area'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-4'>
            <div className='card no-hover text-center'>
              <div className='image-over'>
                {/* Author */}
                <div className='author'>
                  <div className='author-thumb avatar-lg'>
                    <img
                      className='rounded-circle'
                      src={userData?.Image || '/img/auction_1.jpg'}
                      alt=''
                    />
                  </div>
                </div>
              </div>
              {/* Card Caption */}
              <div className='card-caption col-12 p-0'>
                {/* Card Body */}
                <div className='card-body mt-4'>
                  <h5 className='mb-3'>{userData?.Name}</h5>
                </div>
                {/* <p style={{ fontSize: "x-small" }}>
                  {userData?.MetamaskAddress}
                </p> */}
              </div>
            </div>
          </div>
          <div className='col-12 col-md-7'>
            {/* Intro */}
            <div className='intro mt-5 mt-lg-0 mb-4 mb-lg-5'>
              <div className='intro-content'>
                <span>Get Started</span>
                <h3 style={{ color: 'black' }} className='mt-3 mb-0'>
                  Create Item
                </h3>
              </div>
            </div>
            {/* Item Form */}
            <form className='item-form card no-hover'>
              <div className='row'>
                <div className='col-12'>
                  <div className='input-group form-group'>
                    <div className='custom-file'>
                      <input
                        type='file'
                        className='custom-file-input'
                        id='inputGroupFile01'
                        onChange={changeHandler}
                      />
                      <label
                        className='custom-file-label'
                        htmlFor='inputGroupFile01'
                        required='required'
                      >
                        {isFilePicked && selectedFile['size'] <= 10000000
                          ? `${selectedFile.name}`
                          : 'Choose File'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group mt-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='name'
                      name='name'
                      placeholder='Item Name'
                      required='required'
                    />
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <textarea
                      className='form-control'
                      id='textarea'
                      name='textarea'
                      placeholder='Description'
                      cols={30}
                      rows={3}
                      defaultValue={''}
                    />
                  </div>
                </div>
                <div className='col-12'>
                  <button
                    className='btn w-100 mt-3 mt-sm-4'
                    type='submit'
                    onClick={onSubmit}
                  >
                    {isCreating ? 'Creating.......' : 'Create Item'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Create
