import React, { Component, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Web3 from 'web3'
import Rating from '@mui/material/Rating'
import AuctionModal from '../Auctions/AuctionModal'
import { useSelector } from 'react-redux'
import { selectUserAddress } from '../../features/userSlice'
import SaleModal from '../Collections/SaleModal'
import { withRouter } from 'react-router'
import { db } from '../../firebase'
import './ItemDetails.css'
import { createHistory } from 'history'

import {
  CONTRACT_ADDRESS,
  ESCROW_CONTRACT_ABI,
  ESCROW_CONTRACT_ADDRESS
} from '../../config'
import { createFalse } from 'typescript'

const eth_base = 'https://mumbai.polygonscan.com/token/'
const opensea = 'https://testnets.opensea.io/assets/mumbai'
let tokenId
let metaData
let url

const initData = {
  itemImg: '/img/auction_2.jpg',
  date: '2022-03-30',
  tab_1: 'Bids',
  tab_2: 'History',
  tab_3: 'Details',
  ownerImg: '/img/avatar_1.jpg',
  itemOwner: '',
  created: '15 Jul 2021',
  title: 'Walking On Air',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.',
  price_1: '1.5 ETH',
  price_2: '$500.89',
  count: '1 of 5',
  size: '14000 x 14000 px',
  volume: '64.1',
  highest_bid: '2.9 BNB',
  bid_count: '1 of 5',
  btnText: 'Place a Bid'
}

const tabData_1 = [
  {
    id: '1',
    img: '/img/avatar_1.jpg',
    price: '14 ETH',
    time: '4 hours ago',
    author: '@arham'
  },
  {
    id: '2',
    img: '/img/avatar_2.jpg',
    price: '10 ETH',
    time: '8 hours ago',
    author: '@junaid'
  },
  {
    id: '3',
    img: '/img/avatar_3.jpg',
    price: '12 ETH',
    time: '3 hours ago',
    author: '@yasmin'
  }
]

const tabData_2 = [
  {
    id: '1',
    img: '/img/avatar_6.jpg',
    price: '32 ETH',
    time: '10 hours ago',
    author: '@hasan'
  },
  {
    id: '2',
    img: '/img/avatar_7.jpg',
    price: '24 ETH',
    time: '6 hours ago',
    author: '@artnox'
  },
  {
    id: '3',
    img: '/img/avatar_8.jpg',
    price: '29 ETH',
    time: '12 hours ago',
    author: '@meez'
  }
]

const sellerData = [
  {
    id: '1',
    img: '/img/avatar_1.jpg',
    seller: 'Address',
    post: 'Creator'
  },
  {
    id: '2',
    img: '/img/avatar_2.jpg',
    seller: 'Address',
    post: 'Owned by'
  }
]
let tm
const Timer = ({ start, duration }) => {
  const [time, setTime] = useState(start + duration - Date.now())

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(t => t - 1000)
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const showTime = curr => {
    let t = parseInt(curr / 1000)
    const sec = t % 60
    t = parseInt(t / 60)
    const min = t % 60
    t = parseInt(t / 60)

    let timer = ''
    if (t > 0) timer += t + 'H '
    if (min > 0) timer += min + 'M '
    timer += sec + 'S'

    return timer
  }
  tm = time
  if (time > 0) return <div className='d-flex'>{showTime(time)}</div>
  return <div>Ended</div>
}

let address
let nftOwner
let user = []

class ItemDetails extends Component {
  callApproveFunction = async () => {}

  showDescription = desc => {
    const txt = []
    desc.split('\\n').forEach(line => {
      txt.push(line)
      txt.push(<br />)
    })
    return txt
  }

  state = {
    initData: {},
    tabData_1: [],
    tabData_2: [],
    sellerData: [],
    isLoaded: false,
    data: {},
    auctions: {},
    txData: {},
    date: null,
    error: null,
    value: 0,
    type: null,
    currentTime: null,
    id: null,
    isOpen: false,
    bids: [],
    saleOpen: false,
    done: undefined,
    metaAddress: null,
    userData: null
  }

  openModal = () => {
    this.setState({ isOpen: true })
  }
  closeModal = () => this.setState({ isOpen: false })
  saleOpenModal = () => this.setState({ saleOpen: true })
  saleCloseModal = () => this.setState({ saleOpen: false })
  loadMedia = src => {
    var img = new Image()
    img.onerror = () => {
      this.setState({ ...this.state, vid: src })
    }
    img.onload = () => {
      this.setState({ ...this.state, img: src })
    }
    img.src = src
  }
  fetchImageObject = async uri => {
    try {
      metaData = `https://sainft.infura-ipfs.io/ipfs/${uri}`
      axios.get(metaData).then(resp => {
        url = `https://sainft.infura-ipfs.io/ipfs/${resp.data.url}`
        this.loadMedia(url)
      })
    } catch (error) {
      console.log(error)
    }
  }

  getNftById = async id => {
    try {
      const res = await axios.post(
        'https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft',
        {
          query: `
          {
            nftentity(id: "${id}") {
              id
              uri
              owner
              creator {
                id
              }
              name
              description
              sale {
                id
                owner
                token {
                  id
                }
                buyer
                status
                saleCreatedAt
                saleEndedAt
                txnHash
                price
              }
              auction {
                id
                owner
                reservePrice
                auctionCreatedAt
                auctionEndedAt
                duration
                firstBidTime
                lastBid {
                  id
                  bid
                  bidder
                }
                bids{
                  id
                  bid
                  bidder
                }
              }
            }
          }`
        }
      )

      let txTime = res.data.data.nftentity.auction?.auctionCreatedAt

      if (txTime) {
        const current = Date.now()

        let diff = current - txTime

        var creationTime = current + txTime

        creationTime = new Date(creationTime).getTime()

        var createdAt = new Date(diff).getHours()
      }

      this.setState(() => ({
        isLoaded: true,
        data: res.data.data.nftentity,
        bids: res.data.data.nftentity.auction?.bids,
        auctions: res.data.data.nftentity.auction,
        nftOwner: res.data.data.nftentity.creator.id,
        date: createdAt ?? null,
        currentTime: creationTime,
        type: 'Listed'
      }))

      // console.log("type:", this.state.type);
      nftOwner = this.state.data.creator.id

      this.fetchUserData(nftOwner)

      this.fetchImageObject(res.data.data.nftentity.uri)
    } catch (error) {
      this.setState(() => ({ error }))
      console.log(error)
    }
  }

 
  placeBid = async () => {
    if (typeof window.ethereum != 'undefined') {
      const web3 = new Web3(window.ethereum)
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      address = accounts[0]

      const escrowContract = new web3.eth.Contract(
        ESCROW_CONTRACT_ABI,
        ESCROW_CONTRACT_ADDRESS
      )
      let bid = document.getElementById('bid').value
      let alertFlag = false
      // console.log("path", path);
      await escrowContract.methods
        .createBid(tokenId)
        .send({
          value: web3.utils.toWei(bid, 'ether'),
          from: this.state.metaAddress
        })
        .on('transactionHash', function () {
          console.log('Transaction Processing......')
        })
        .on('confirmation', function () {
          console.log('Transaction Confirmed')
        })
        .on('receipt', function () {
          // receipt example
          console.log('Transaction Complete')
          if (!alertFlag) {
            alertFlag = true;
          alert(`Your Bid for ${bid} MATIC Has Been Recieved!`)}
          // window.location.reload()
        })
        .on('error', function () {
          console.log('Errored')
          alert(`Error Encountered`)
          // window.location.reload()
        })
        .on('disconnect', function() {
          console.log('Connection lost or disconnected')
        })
    } else {
      alert('Connect To MetaMask')
    }
  }


  handleEndAuc = async () => {
    if (typeof window.ethereum != 'undefined') {
      const web3 = new Web3(window.ethereum)
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      address = accounts[0]

      const escrowContract = new web3.eth.Contract(
        ESCROW_CONTRACT_ABI,
        ESCROW_CONTRACT_ADDRESS
      )
let alertFlag = false;
      await escrowContract.methods
        .endAuction(tokenId)
        .send({
          from: this.state.metaAddress
        })
        .on('transactionHash', function () {
          console.log('Transaction Processing.......')
        })
        .on('receipt', function () {
          console.log('Receipt')
        })
        .on('confirmation', function () {
          console.log('Transaction Confirmed')
          if (!alertFlag) {
            alertFlag = true;
          alert(`Auction Has Stopped Now. The NFT Will Be Shortly Sent To Respective Owner!`)
          window.location.reload()
          }
        })
        .on('error', function () {
          console.log('ERRORED')
          alert('Errored')
          window.location.reload()
        })
    }
  }

  handleEndSale = async () => {
    const web3 = new Web3(window.ethereum)
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts'
    })
    address = accounts[0]

    const escrowContract = new web3.eth.Contract(
      ESCROW_CONTRACT_ABI,
      ESCROW_CONTRACT_ADDRESS
    )
let alertFlag= false;
    await escrowContract.methods
      .cancelSale(tokenId)
      .send({
        from: this.state.metaAddress
      })
      .on('transactionHash', function () {
        console.log('Transaction Processing.......')
      })
      .on('receipt', function () {
        console.log('Receipt')
      })
      .on('confirmation', function () {
        console.log('Transaction Confirmed')
        if (!alertFlag) {
          alertFlag = true;
        alert(`NFT Sale Stopped! It Will Shortly Be Sent To The Respective Owner!`)
        window.location.reload()
        }
      })
      .on('error', function () {
        alert('ERRORED')
        window.location.reload()
      })
  }

  handleBuy = async () => {
    let price = this.state.data.sale?.price
    const web3 = new Web3(window.ethereum)
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts'
    })
    address = accounts[0]
    // const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
    const escrowContract = new web3.eth.Contract(
      ESCROW_CONTRACT_ABI,
      ESCROW_CONTRACT_ADDRESS
    )
    // price =
    let alertFlag = false;
    console.log('price', price, 'address', this.state.metaAddress)
    await escrowContract.methods
      .buySaleToken(tokenId)
      .send({
        value: price,
        from: this.state.metaAddress
      })
      .on('transactionHash', function () {
        console.log('Transaction Processing...........')
      })
      .on('receipt', function () {
        console.log('Receipt')
      })
      .on('confirmation', function () {
        // const confirm = "Confirmed";
        console.log('Transaction Confirmed')
        if (!alertFlag) {
          alertFlag = true;
        alert(`Thank You For Buying This NFT!`)
        window.location.reload()
        }
      })
      .on('error', async function () {
        alert('Error Encountered')
        window.location.reload()
      })
  }

  componentDidUpdate () {
    const path = window.location.pathname
    const id = path.split('/')[2]
    if (id !== this.state.id) {
      this.loadPage()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.location === prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  loadPage () {
    const path = window.location.pathname
    const id = path.split('/')[2]
    tokenId = Web3.utils.hexToNumber(id)
    this.getNftById(id)
    this.setState({
      initData,
      tabData_1,
      tabData_2,
      sellerData,
      id
    })
    address = sessionStorage.getItem('meta-address')
    address = address?.slice(1, -1)
    // console.log(address);
    this.setState({ metaAddress: address })
    // console.log(this.state.metaAddress);
  }
  fetchUserData = async () => {
    const response = db.collection('userProfile').doc(nftOwner)
    response
      .get()
      .then(doc => {
        if (doc.exists) {
          this.setState({ userData: doc.data() })
        } else {
          console.log('No such doc')
        }
      })
      .catch(error => {
        console.log('Error getting doc', error)
      })
  }

  componentDidMount () {
    this.loadPage()
    this.fetchUserData()
  }

  render () {
    return (
      <section
        style={{ backgroundColor: 'white',backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)" }}
        className='mt-4 item-details-area'
      >
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-12 col-lg-5'>
              <div className='item-info'>
                <div className=' imageTag mr-4 p-4 item-thumb text-center'>
                  {!this.state.vid ? (
                    <img
                      style={{
                        width: '400px',
                        height: '400px',
                        padding: '10px'
                      }}
                      src={this.state.img}
                      alt=''
                    />
                  ) : (
                    <video
                      autoPlay
                      loop
                      muted
                      style={{ width: '100%' }}
                      src={this.state.vid}
                      alt=''
                    ></video>
                  )}
                </div>
                {/* <div className="card no-hover countdown-times my-4">
                  <div className="countdown d-flex justify-content-center">
                    <Timer
                      start={this.state.auctions.auctionCreatedAt * 1000}
                      duration={this.state.auctions.duration * 60 * 60 * 1000}
                    />
                  </div>
                </div> */}
                {/* Netstorm Tab */}
                <ul className='mt-5 p-2 netstorm-tab nav' id='nav-tab'>
                  <li>
                    <a
                      className='active'
                      id='nav-home-tab'
                      data-toggle='pill'
                      href='#nav-home'
                    >
                      <h5 style={{ color: 'black' }} className='m-0'>
                        {this.state.initData.tab_1}
                      </h5>
                    </a>
                  </li>
                  <li>
                    <a
                      id='nav-profile-tab'
                      data-toggle='pill'
                      href='#nav-profile'
                    >
                      <h5 style={{ color: 'black' }} className='m-0'>
                        {this.state.initData.tab_2}
                      </h5>
                    </a>
                  </li>
                  <li>
                    <a
                      id='nav-contact-tab'
                      data-toggle='pill'
                      href='#nav-contact'
                    >
                      <h5 style={{ color: 'black' }} className='m-0'>
                        {this.state.initData.tab_3}
                      </h5>
                    </a>
                  </li>
                </ul>
                {/* Tab Content */}
                <div className='tab-content' id='nav-tabContent'>
                  <div className='tab-pane fade show active' id='nav-home'>
                    <ul>
                      {/* Single Tab List */}
                      {/* {this.state.tabData_1.map((item, idx) => { */}
                      {/* return ( */}
                      <li
                        // key={`tdo_${idx}`}
                        className='single-tab-list d-flex align-items-center'
                      >
                        <p className='m-0'>
                          {this.state.auctions?.bids ? (
                            this.state.bids.map((item, i) => {
                              return (
                                <li className='bid__history' key={i}>
                                  {' '}
                                  Bid listed for
                                  {Web3.utils.fromWei(item?.bid)} MATIC by
                                  {item?.bidder}
                                </li>
                              )
                            })
                          ) : (
                            <li className='bid__history'>No Bids Yet</li>
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-pane fade' id='nav-profile'>
                    <ul className='list-unstyled'>
                      <li className='single-tab-list d-flex align-items-center'>
                        <img
                          className='avatar-sm rounded-circle mr-3'
                          src={this.state.initData.itemImg}
                          alt=''
                        />
                        {(this.state.auctions &&
                          this.state.auctions?.reservePrice && (
                            <p className='bid__history'>
                              {this.state.type} for
                              <br />
                              {Web3.utils.fromWei(
                                this.state.auctions?.reservePrice,
                                'ether'
                              )}{' '}
                              MATIC by {this.state.data.auction?.owner}
                            </p>
                          )) ||
                          (this.state.data.sale && (
                            <p className='bid__history'>
                              Listed for
                              <br />
                              {Web3.utils.fromWei(
                                this.state.data.sale?.price
                              )}{' '}
                              MATIC by {this.state.data.sale?.owner}
                            </p>
                          )) || (
                            <p className='bid__history'>
                              {this.state.data.sale?.buyer ? (
                                `Bought for
                              ${this.state.data?.sale?.price} MATIC
                            by ${this.state.data?.sale?.buyer}`
                              ) : (
                                <span className='bid__history'>
                                  No Activity
                                </span>
                              )}
                            </p>
                          )}
                      </li>
                      {/* ); */}
                      {/* })} */}
                    </ul>
                  </div>
                  <div className='tab-pane fade' id='nav-contact'>
                    {/* Single Tab List */}
                    <div className='owner-meta d-flex align-items-center mt-3'>
                      <a
                        className='owner d-flex align-items-center ml-2'
                        href={`${url}`}
                        target='_blank'
                      >
                        View On Ipfs
                      </a>
                    </div>
                    <div className='owner-meta d-flex align-items-center mt-3'>
                      <a
                        className='owner d-flex align-items-center ml-2'
                        href={`${metaData}`}
                        target='_blank'
                      >
                        View MetaData
                      </a>
                    </div>
                    <div className='owner-meta d-flex align-items-center mt-3'>
                      <a
                        className='owner d-flex align-items-center ml-2'
                        href={`${eth_base}${CONTRACT_ADDRESS}?a=${tokenId}`}
                        target='_blank'
                      >
                        View On EtherScan
                      </a>
                    </div>
                    <div className='owner-meta d-flex align-items-center mt-3'>
                      <a
                        className='owner d-flex align-items-center ml-2'
                        href={`https://testnets.opensea.io/assets/mumbai/${CONTRACT_ADDRESS}/${tokenId}`}
                        target='_blank'
                      >
                        View On OpenSea
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              {/* Content */}

              <div className='content mt-5 mt-lg-0'>
                <h3 style={{ color: 'black' }} className='m-0'>
                  {this.state.data.name}
                </h3>
                {this.state.data.description && (
                  <p>{this.showDescription(this.state.data.description)}</p>
                )}
                {/* Owner */}
                {/* <div className="owner d-flex align-items-center">
                  <span>Owned By</span>
                  <a className="owner-meta d-flex align-items-center ml-3">
                    <img
                      className="avatar-sm rounded-circle"
                      src={this.state.initData.ownerImg}
                      alt=""
                    />
                    <h5 style={{ fontSize: "small" }} className="ml-2">
                      {this.state.data.owner}
                    </h5>
                  </a>
                </div> */}
                {/* Item Info List */}

                <div className='row items'>
                  {/* <div className="col-12 col-md-6 item px-lg-2"> */}

                  <div
                    onClick={() =>
                      this.props.history.push(
                        `/creator/${this.state.data.creator?.id}`
                      )
                    }
                    style={{ display: 'flex' }}
                    className='card1'
                  >
                    <img
                      style={{ cursor: 'pointer' }}
                      className='mb-5 avatar-md rounded-circle'
                      src={
                        this.state.userData?.Image ||
                        this.state.initData.itemImg
                      }
                      alt=''
                    />
                    <div className='card__content'>
                      <h5
                        style={{
                          color: 'black',
                          cursor: 'pointer'
                        }}
                        className='card1-text'
                      >
                        {this.state.data.creator?.id || ' '}
                      </h5>
                      {this.state.data.creator?.id && (
                        <span
                          style={{ color: 'black' }}
                          className='card__creator'
                        >
                          Creator
                        </span>
                      )}

                      {/* <div className="price d-flex justify-content-between align-items-center">
                        <span>Creator</span>
                      </div> */}
                    </div>
                  </div>

                  {/* </div> */}
                  {this.state.data.auction != null ? (
                    <div className='col-12 item px-lg-2'>
                      <div
                        // style={{ width: "540px", height: "100px" }}
                        className='card2'
                      >
                        <div className='card2-content'>
                          <h5 style={{ color: 'black' }} className='mt-0 mb-2'>
                            Highest Bid
                          </h5>
                          {/* <h4 className='mt-0 mb-2'>Current Price</h4> */}
                          {/* <div className="price d-flex justify-content-between align-items-center"> */}
                          <span style={{ color: 'black' }}>
                            {this.state.auctions?.lastBid?.bid
                              ? `${Web3.utils.fromWei(
                                  this.state.auctions.lastBid?.bid,
                                  'ether'
                                )} MATIC`
                              : `${Web3.utils.fromWei(
                                  this.state.auctions.reservePrice
                                )} MATIC`}
                          </span>
                        </div>
                      </div>
                      {/* <Timer
                        start={this.state.auctions.auctionCreatedAt * 1000}
                        duration={this.state.auctions.duration * 60 * 60 * 1000}
                      /> */}
                      {this.state.auctions?.owner != this.state.metaAddress &&
                        this.state.auctions.auctionCreatedAt * 1000 +
                          this.state.auctions.duration * 60 * 60 * 1000 >
                          Date.now() && (
                          <div>
                            <input
                              style={{ color: 'black', border: '1px solid' }}
                              type='text'
                              placeholder='Enter Bid Value in MATIC'
                              className='d-block btn btn-bordered-white mt-4'
                              id='bid'
                            ></input>
                            <a
                              style={{ color: 'white' }}
                              className='d-block btn btn mt-4'
                              onClick={this.placeBid}
                            >
                              Place Bid
                            </a>
                          </div>
                        )}
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div>
                  {this.state.data.sale != null &&
                  this.state.data.sale?.owner != this.state.metaAddress ? (
                    <div>
                      <div className='col-12 mt-3 item px-lg-2'>
                        <div className='card2'>
                          {/* <div className="price d-flex justify-content-between align-items-center"> */}
                          <h5
                            style={{ color: 'black' }}
                            className='price__text'
                          >
                            Price:{' '}
                            {Web3.utils.fromWei(this.state.data.sale?.price)}{' '}
                            MATIC
                          </h5>

                          {/* <span>{this.state.auctions?.reservePrice}</span> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <a
                        style={{ color: 'white' }}
                        className='d-block btn btn mt-4'
                        onClick={this.handleBuy}
                      >
                        Buy
                      </a>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>

                {this.state.data.owner === this.state.metaAddress &&
                !this.state.data.auction &&
                address ? (
                  <a
                    style={{ color: 'white' }}
                    className='d-block btn btn mt-4'
                    onClick={this.openModal}
                  >
                    Create Auction
                  </a>
                ) : (
                  this.state.auctions?.owner === this.state.metaAddress &&
                  address &&
                  this.state.auctions && (
                    <a
                      style={{ color: 'white' }}
                      className='d-block btn btn mt-4'
                      onClick={this.handleEndAuc}
                    >
                      End Auction
                    </a>
                  )
                )}
                {this.state.data.owner === this.state.metaAddress &&
                address &&
                !this.state.data.sale ? (
                  <a
                    style={{ color: 'white' }}
                    onClick={this.saleOpenModal}
                    className='d-block btn btn mt-4'
                  >
                    Create Sale
                  </a>
                ) : (
                  this.state.data.sale?.owner === this.state.metaAddress &&
                  this.state.data.sale && (
                    <a
                      style={{ color: 'white' }}
                      className='d-block btn btn mt-4'
                      onClick={this.handleEndSale}
                    >
                      End Sale
                    </a>
                  )
                )}
                {this.state.isOpen ? (
                  <AuctionModal
                    onHide={this.closeModal}
                    closeModal={this.closeModal}
                    isOpen={this.state.isOpen}
                    handleSubmit={this.handleSubmit}
                  />
                ) : null}
                {this.state.saleOpen ? (
                  <SaleModal
                    onHide={this.saleCloseModal}
                    closeModal={this.saleCloseModal}
                    isOpen={this.state.saleOpen}
                    handleSubmit={this.handleSubmit}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(ItemDetails)
