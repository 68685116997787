import React, { Component, useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import axios from "axios";
import { withRouter } from "react-router";
import AuctionModal from "./AuctionModal";
import Web3 from "web3";
import CircularProgress from "@mui/material/CircularProgress";

const initData = {
  pre_heading: "Auctions",
  heading: " All Auctions",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
  btnText: "Load More",
};

const data = [
  {
    id: "1",
    img: "/img/auction_1.jpg",
    date: "2021-12-09",
    title: "Virtual Worlds",
    seller_thumb: "/img/avatar_1.jpg",
    seller: "@Richard",
    price: "1.5 BNB",
    count: "1 of 1",
  },
  {
    id: "2",
    img: "/img/auction_2.jpg",
    date: "2021-10-05",
    title: "Collectibles",
    seller_thumb: "/img/avatar_2.jpg",
    seller: "@JohnDeo",
    price: "2.7 BNB",
    count: "1 of 1",
  },
  {
    id: "3",
    img: "/img/auction_3.jpg",
    date: "2021-09-15",
    title: "Arts",
    seller_thumb: "/img/avatar_3.jpg",
    seller: "@MKHblots",
    price: "2.3 BNB",
    count: "1 of 1",
  },
  {
    id: "4",
    img: "/img/auction_4.jpg",
    date: "2021-12-29",
    title: "Robotic Arts",
    seller_thumb: "/img/avatar_4.jpg",
    seller: "@RioArham",
    price: "1.8 BNB",
    count: "1 of 1",
  },
  {
    id: "5",
    img: "/img/auction_5.jpg",
    date: "2022-01-24",
    title: "Magazine Fall",
    seller_thumb: "/img/avatar_5.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
  {
    id: "6",
    img: "/img/auction_6.jpg",
    date: "2022-03-30",
    title: "Inspiration",
    seller_thumb: "/img/avatar_6.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
  {
    id: "7",
    img: "/img/auction_7.jpg",
    date: "2022-01-24",
    title: "Design Illusions",
    seller_thumb: "/img/avatar_7.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
  {
    id: "8",
    img: "/img/auction_8.jpg",
    date: "2022-03-30",
    title: "Design Illusions",
    seller_thumb: "/img/avatar_8.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
  {
    id: "9",
    img: "/img/auction_9.jpg",
    date: "2022-03-30",
    title: "Design Illusions",
    seller_thumb: "/img/avatar_4.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
  {
    id: "10",
    img: "/img/auction_10.jpg",
    date: "2022-03-30",
    title: "Infinity",
    seller_thumb: "/img/avatar_1.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
  {
    id: "11",
    img: "/img/auction_11.jpg",
    date: "2022-01-24",
    title: "Sports",
    seller_thumb: "/img/avatar_2.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
  {
    id: "12",
    img: "/img/auction_12.jpg",
    date: "2022-03-30",
    title: "Characteristics",
    seller_thumb: "/img/avatar_3.jpg",
    seller: "@ArtNox",
    price: "1.7 BNB",
    count: "1 of 1",
  },
];

const NftView = ({ src }) => {
  const [img, setImg] = useState(true);
  const loadMedia = (src) => {
    var img = new Image();
    img.onerror = () => {
      setImg(false);
    };
    img.src = src;
  };
  useEffect(() => {
    loadMedia(src);
  }, []);

  return (
    <img
      style={{ height: "200px", width: "300px" }}
      className="card-img-top"
      src={src}
      alt=""
    />
  );
};
const Timer = ({ start, duration }) => {
  const [time, setTime] = useState(start + duration - Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((t) => t - 1000);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const showTime = (curr) => {
    let t = parseInt(curr / 1000);
    const sec = t % 60;
    t = parseInt(t / 60);
    const min = t % 60;
    t = parseInt(t / 60);

    let timer = "";
    if (t > 0) timer += t + "H ";
    if (min > 0) timer += min + "M ";
    timer += sec + "S";

    return timer;
  };

  if (time > 0) return <div>{showTime(time)}</div>;
  return <div>Ended</div>;
};
class AuctionsTwo extends Component {
  _isMounted = 1;
  constructor(props) {
    super(props);
    this.auctions = React.createRef();
  }

  state = {
    initData: {},
    data: [],
    liveAuctions: [],
    source: [],
    isOpen: false,
    currentLoad: 0,
    loading: true,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  fetchImageObject = async () => {
    try {
      if (this._isMounted === 1) {
        this.state.liveAuctions.forEach((nft) =>
          axios
            .get(`https://sainft.infura-ipfs.io/ipfs/${nft.token.uri}`)
            .then((resp) =>
              this.setState({
                source: {
                  ...this.state.source,
                  [nft.id]: `https://sainft.infura-ipfs.io/ipfs/${resp.data.url}`,
                },
              })
            )
        );
      }

      if (this._isMounted === 1) {
        this.setState(() => ({
          isLoaded: true,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  getLiveAuction = async () => {
    const query = `
    {
      auctions (where:{status_in:[OPEN,STARTED]}){
        id
        token {
          id
          uri
          name
          description
        }
        reservePrice
        owner
        firstBidTime
        duration
        lastBid {
          id
          bidder
          bid
          status
        }
        bids {
          id
          bidder
          bid
        }
        auctionCreatedAt
        auctionEndedAt
        txnHash
      }
    }
  `;
    try {
      const response = await axios.post(
        "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
        {
          query,
        }
      );
      if (this._isMounted === 1) {
        this.setState({ loading: true });

        this.setState(
          {
            isLoaded: true,
            liveAuctions: response.data.data.auctions,
            currentLoad:
              response.data.data.auctions.length > 6
                ? 6
                : response.data.data.auctions.length,
          },
          () => {
            this.fetchImageObject();
          }
        );
      }
      // console.log(this.state.liveAuctions);
    } catch (error) {
      this.setState(() => ({ error }));
      console.log(error);
    }
  };

  handleLoadMore = () => {
    if (this.state.currentLoad <= this.state.liveAuctions.length) {
      if (this.state.currentLoad + 6 <= this.state.liveAuctions.length) {
        this.setState({ loading: true });

        this.setState({ currentLoad: this.state.currentLoad + 6 });
        this.setState({ loading: true });
      } else {
        this.setState({ loading: true });

        this.setState({ currentLoad: this.state.liveAuctions.length });
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    this._isMounted = 1;

    this.setState({
      initData: initData,
      data: data,
    });
    document.addEventListener("scroll", this.trackScroll);

    this.getLiveAuction();
  }
  trackScroll = () => {
    if (
      this.auctions.current.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      // console.log("We reached the bottom");

      this.handleLoadMore();

      if (this.state.currentLoad === this.state.liveAuctions.length) {
        this.setState({ loading: false });

        document.removeEventListener("scroll", this.trackScroll);
      }
    }
    if (this.state.currentLoad === this.state.liveAuctions.length) {
      this.setState({ loading: false });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.liveAuctions.length !== this.state.liveAuctions.length) {
      document.addEventListener("scroll", this.trackScroll);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScroll);
  }
  render() {
    var { liveAuctions } = this.state;
    const { currentLoad } = this.state;

    return (
      <section
        style={{ backgroundColor: "white",backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)", paddingBottom: "130px", }}
        className="live-auctions-area load-more"
        ref={this.auctions}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              {/* Intro */}
              <div className="intro text-center">
                <span>{this.state.initData.pre_heading}</span>
                <h3 style={{ color: "black" }} className="mt-3 mb-3">
                  {this.state.initData.heading}
                </h3>

                {this.state.isOpen ? (
                  <AuctionModal
                    onHide={this.closeModal}
                    closeModal={this.closeModal}
                    isOpen={this.state.isOpen}
                    handleSubmit={this.handleSubmit}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="row items">
            {liveAuctions &&
              liveAuctions.slice(0, currentLoad).map((item, idx) => {
                return (
                  <div
                    key={`auct_${idx}`}
                    className="col-10 col-sm-4 mr-5 col-lg-3 mt-4"
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        width: "120%",
                        marginRight: "50px",
                        marginLeft: "50px",
                      }}
                      onClick={() =>
                        this.props.history.push(
                          `/details/${item.token.id}`,
                          this.state.item
                        )
                      }
                      className="mr-5 card"
                    >
                      <div className="image-over">
                        <NftView src={this.state.source[item.id]} />

                        {/* <a href="/item-details">
                          <img className="card-img-top" src={item.img} alt="" />
                        </a> */}
                      </div>
                      {/* Card Caption */}
                      <div className="card-caption col-12 p-0">
                        {/* Card Body */}
                        <div className="card-body">
                          <div className="countdown-times mb-3">
                            <div
                              className="countdown d-flex justify-content-center"
                              data-date={item.date}
                            />
                          </div>
                          <h5
                            onClick={() =>
                              this.props.history.push(
                                `/details/${item.token.id}`
                              )
                            }
                            className="mb-0"
                          >
                            {item.token.name}
                          </h5>
                          <a className="seller d-flex align-items-center my-3">
                            <span
                              className=""
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            >
                              {item.owner}
                            </span>
                          </a>
                          <div className="card-bottom d-flex justify-content-between">
                            <span>
                              {Web3.utils.fromWei(item.reservePrice, "ether")}{" "}
                              MATIC
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row">
            <div className="col-12 text-center">
              {this.state.loading && <CircularProgress color="inherit" />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(AuctionsTwo);
