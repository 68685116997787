import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import SaleModal from "./SaleModal.js";
import Web3 from "web3";
import "./Collection.css";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json/collections";

const NftView = ({ src }) => {
  const [img, setImg] = useState();
  const loadMedia = (src) => {
    setImg(true);
    var img = new Image();
    img.onerror = () => {
      setImg(false);
    };
    img.src = src;
  };
  useEffect(() => {
    loadMedia(src);
    console.log(img);
  }, []);

  return (
    <img
      style={{ height: "200px", width: "300px" }}
      className="card-img-top"
      src={src}
      alt=""
    />
  );
};

class Collections extends Component {
  _isMounted = 1;

  state = {
    data: {},
    collectionData: [],
    liveSale: [],
    saleNft: [],
    ImgUri: [],
    nftImage: "",
    source: [],
    isOpen: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => {
    console.log("ok");
    this.setState({ isOpen: false }, () => {
      console.log(this.state.isOpen);
    });
  };
  handleSubmit = (tokenId, price) => {
    console.log(tokenId);
    console.log(price);
  };

  fetchImageObject = async () => {
    try {
      if (this._isMounted === 1) {
        this.state.liveSale?.forEach((nft) =>
          axios
            .get(`https://sainft.infura-ipfs.io/ipfs/${nft.token.uri}`)
            .then((resp) =>
              this.setState({
                source: {
                  ...this.state.source,
                  [nft.id]: `https://sainft.infura-ipfs.io/ipfs/${resp.data.url}`,
                },
              })
            )
        );
      }

      if (this._isMounted === 1) {
        this.setState(() => ({
          isLoaded: true,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  getliveSale = async () => {
    const query = `
    {
      sales (where:{status:OPEN}){
        id
        token {
          id
          uri
          name
        }
        price
        owner
        buyer
        status
        saleCreatedAt
        saleEndedAt
        txnHash
      }
    }`;
    try {
      const res = await axios.post(
        "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
        {
          query,
        }
      );
      if (this._isMounted === 1) {
        this.setState(
          {
            isLoaded: true,
            liveSale: res.data.data.sales,
            saleNft:
              res.data.data.sales.length > 6 ? 6 : res.data.data.sales.length,
          },
          () => {
            this.fetchImageObject();
          }
        );
      }
    } catch (error) {
      this.setState(() => ({ error }));
      console.log(error);
    }
  };

  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          collectionData: res.data.collectionData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
    this._isMounted = 1;
    this.fetchImageObject();

    this.getliveSale();
  }
  componentWillUnmount() {
    this._isMounted = 0;
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    var { source } = this.state;
    var { saleNft } = this.state;

    return (
      <section
        className="popular-collections-area"
        style={{paddingBottom:"40px",backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)", }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro d-flex justify-content-between align-items-end m-0">
                <div className="intro-content">
                  <a>
                    <span>On going sale</span>
                  </a>
                  <h3 style={{ color: "black" }} className="mt-3 mb-0">
                    Live Sale
                  </h3>
                </div>
                <div className="btn">
                  <a
                    style={{ color: "white" }}
                    href="/allSale"
                    className="btn content-btn text-left"
                  >
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="row items">
              {this.state.liveSale &&
                this.state.liveSale.slice(0, saleNft).map((item, idx) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.props.history.push(
                          `/details/${item.token.id}`,
                          this.state.data
                        )
                      }
                      key={`cd_${idx}`}
                      className="saleCard__container"
                      // className="col-12 mr-4 col-sm-6 col-lg-3 item"
                    >
                      <div className="sale__card">
                        <div className="card__img">
                          <div
                            style={{
                              backgroundImage: `url(${
                                this.state.source[item.id]
                              })`,
                              height: "235px",
                              width: "100%",
                              minWidth: "220px",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>
                        <div className="saleCard__content">
                        <span
                                style={{ fontSize: "large", color: 'white' }}
                                className="ml-2 mt-2"
                              >
                                {item.token?.name}
                              </span>
                          <p style={{ color: "white" }}>Owned By</p>
                          <span className="saleCard__owner">{item.owner}</span>
                          <p>
                            <span style={{ color: "white" }}>
                              Price: {Web3.utils.fromWei(item.price)} MATIC
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Collections);
