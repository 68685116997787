import React, { Component } from 'react'
import axios from 'axios'
import './Footer.css'
import { withRouter } from 'react-router'
import { send } from 'emailjs-com'
const BASE_URL =
  'https://my-json-server.typicode.com/themeland/netstorm-json-2/footer'

let address
class Footer extends Component {
  state = {
    data: {},
    socialData: [],
    widgetData_1: [],
    widgetData_2: [],
    error: null,
    email: ''
  }

  handleChange = e => {
    this.setState({ email: e.target.value })
    // console.log(this.state.email);
  }

  sendEmail = e => {
    e.preventDefault()

    send(
      'service_08101s3',
      'template_aqj79qb',
      { email: this.state.email },
      '-7B_-sYyagAD0x0Af'
    )
      .then(result => {
        // window.location.reload();
        // console.log("SUCCESS!", result.status, result.text);
      })
      .catch(error => {
        console.log(error.text)
      })
    alert('Thank You For Subscribing!')
    this.setState({ email: '' })
  }
  componentDidMount () {
    axios
      .get(`${BASE_URL}`)
      .then(res => {
        this.setState({
          data: res.data,
          socialData: res.data.socialData,
          widgetData_1: res.data.widgetData_1,
          widgetData_2: res.data.widgetData_2
        })
        // console.log(this.state.data)
      })
      .catch(err => console.log(err))
    address = sessionStorage.getItem('meta-address')
    address = address?.slice(1, -1)
    // console.log(address);
  }
  render () {
    return (
      <footer style={{ backgroundColor: '#e6e6e6' ,backgroundImage: "linear-gradient(135deg, #ebbba7 0%, #cfc7f8 100%)"}} className='footer-area'>
        {/* Footer Top */}
        <div className='footer-top'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-lg-3 res-margin'>
                {/* Footer Items */}
                <div className='footer-items'>
                  {/* Logo */}
                  <a className='navbar-brand' href='/'>
                    <img src={this.state.data.img} alt='' />
                  </a>
                  <p style={{ color: 'black' }}>
                    Create, Add, Sell your NFTs at this right marketplace where
                    transactions are quick, safe, and secure. So, get started to
                    enjoy the NFT ride.
                  </p>
                  {/* Social Icons */}
                  {/* <div className='social-icons d-flex'>
                    {this.state.socialData.map((item, idx) => {
                      return (
                        <a key={`sd_${idx}`} className={item.link} href='#'>
                          <i className={item.icon} />
                          <i className={item.icon} />
                        </a>
                      )
                    })}
                  </div> */}
                </div>
              </div>

              {/* Footer Items */}
              <div className='footer-component'>
                <div className='footer-items'>
                  {/* Footer Title */}
                  <h4 style={{ color: 'black' }} className='footer-title'>
                    {this.state.data.widget_1}
                  </h4>
                  <ul className='footer__links'>
                    {/* {this.state.widgetData_1.map((item, idx) => {
                      return (
                        <li key={`wdo_${idx}`}>
                          <a style={{ color: "black" }} href="#">
                            {item.text}
                          </a>
                        </li>
                      );
                    })} */}
                    <li
                      onClick={() => this.props.history.push('/explore-3')}
                      style={{ color: 'black', cursor: 'pointer' }}
                      className='footer__item'
                    >
                      All NFTs
                    </li>
                    <li
                      onClick={() => this.props.history.push('#')}
                      style={{ color: 'black', cursor: 'pointer' }}
                      className='footer__item'
                    >
                      How It Works
                    </li>
                    {address ? (
                      <li
                        onClick={() => this.props.history.push('/create')}
                        style={{ color: 'black', cursor: 'pointer' }}
                        className='footer__item'
                      >
                        Create
                      </li>
                    ) : (
                      <li
                        onClick={() => alert('Sign In with Metamask')}
                        style={{ color: 'black', cursor: 'pointer' }}
                        className='footer__item'
                      >
                        Create
                      </li>
                    )}
                    <li
                      style={{ color: 'black', cursor: 'pointer' }}
                      className='footer__item'
                    >
                      Privacy & Terms
                    </li>
                  </ul>
                </div>
              </div>

              <div className='col-12 col-sm-6 col-lg-3'>
                {/* Footer Items */}
                <div className='footer-items'>
                  {/* Footer Title */}
                  <h4 style={{ color: 'black' }} className='footer-title'>
                    {/* {this.state.data.widget_3} */}
                    Community
                  </h4>
                  {/* Subscribe Form */}
                  <div className='subscribe-form d-flex align-items-center'>
                    {/* <form onSubmit={this.sendEmail}>
                      <input
                        type='email'
                        className='form-control'
                        name='from_email'
                        onChange={this.handleChange}
                        value={this.state.email}
                        placeholder='info@yourmail.com'
                      />
                      <button
                        onSubmit={this.sendEmail}
                        type='submit'
                        className='btn'
                      >
                        <i className='icon-paper-plane' />
                      </button>
                    </form> */}
                    <div className='social-icons d-flex '>
                    {this.state.socialData.map((item, idx) => {
                      return (
                        <a key={`sd_${idx}`} className={item.link} href='#'>
                          <i className={item.icon} />
                          <i className={item.icon} />
                        </a>
                      )
                    })}
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                {/* Copyright Area */}
                <div className='copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4'>
                  {/* Copyright Left */}
                  <div style={{ color: 'black' }} className='copyright-left'>
                    ©2022 SAINFT, All Rights Reserved.
                  </div>
                  {/* Copyright Right */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default withRouter(Footer)
