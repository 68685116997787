// import React, { Component } from 'react'
// import Modal from 'react-bootstrap/Modal'
// import Form from 'react-bootstrap/Form'
// import Button from 'react-bootstrap-buttons'
// import Web3 from 'web3'
// import {
//   CONTRACT_ABI,
//   CONTRACT_ADDRESS,
//   ESCROW_CONTRACT_ABI,
//   ESCROW_CONTRACT_ADDRESS
// } from '../../config'

// const handleCreateAuction = async () => {
//   let id = window.location.pathname
//   id = id.split('/')[2]
//   id = Web3.utils.hexToNumber(id)
//   let address
//   if (typeof window.ethereum != 'undefined') {
//     const web3 = new Web3(window.ethereum)
//     const accounts = await window.ethereum.request({
//       method: 'eth_requestAccounts'
//     })
//     address = accounts[0]
//     console.log('address', address)
//     let price = document.getElementById('price').value
//     price = web3.utils.toWei(price, 'ether')
//     const duration = document.getElementById('duration').value

//     const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)

//     await nftContract.methods
//       .approve(`${ESCROW_CONTRACT_ADDRESS}`, id)
//       .send({
//         from: address
//         // to: CONTRACT_ADDRESS,
//       })
//       .on('transactionHash', function () {
//         console.log('Transaction Processing............')
//       })
//       .on('receipt', function () {
//         console.log('Reciept')
//       })
//       .on('confirmation', function () {
//         console.log('Transaction Confirmed')
//       })
//       .on('error', async function () {
//         console.log('Error Encountered')
//       })

//     // const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
//     const escrowContract = new web3.eth.Contract(
//       ESCROW_CONTRACT_ABI,
//       ESCROW_CONTRACT_ADDRESS
//     )
//     await escrowContract.methods
//       .createAuction(id, duration, price)
//       .send({
//         from: address
//         // gas: 23000
//       })
//       .on('transactionHash', function () {
//         console.log('Transaction Processing............')
//       })
//       .on('receipt', function () {
//         console.log('Reciept')
//       })
//       .on('confirmation', function () {
//         console.log('Transaction Confirmed')
//         alert(`Your NFT Has Been Put On Auction!`)
//         window.location.reload()
//       })
//       .on('error', async function () {
//         console.log('Error Encountered')
//         alert(`Encountered an error`)
//         window.location.reload()
//       })
//   } else {
//     alert(
//       "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
//     )
//   }
// }

// export default class AuctionModal extends Component {
//   state = { tokenId: '', price: '', duration: '' }

//   handleChange = e => this.setState({ tokenId: e.target.value })
//   handleChange2 = e => this.setState({ price: e.target.value })
//   handleChange3 = e => this.setState({ duration: e.target.value })

//   render () {
//     return (
//       <Modal
//         centered
//         size='lg'
//         show={this.props.isOpen}
//         onHide={this.props.closeModal}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title> Auction</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ padding: '20px' }}>
//           <Form.Group style={{ padding: '10px' }}>
//             <Form.Label style={{ margin: '10px' }}>Reserve Price:</Form.Label>
//             <Form.Control
//               type='text'
//               onChange={this.handleChange2}
//               value={this.state.price}
//               id='price'
//               placeholder='Enter reserve price in Ethers'
//             />
//           </Form.Group>
//           <Form.Group style={{ padding: '10px' }}>
//             <Form.Label style={{ margin: '10px' }}>Duration:</Form.Label>
//             <Form.Control
//               type='text'
//               onChange={this.handleChange3}
//               value={this.state.duration}
//               id='duration'
//               placeholder='Enter duration in Hours'
//             />
//           </Form.Group>
//         </Modal.Body>
//         <Modal.Footer>
//           {/* <Button
//             variant="primary"
//             type="submit"
//             onClick={() => this.props.closeModal()}
//           >
//             Create Auction
//           </Button> */}
//           <button
//             type='submit'
//             style={{ color: 'black', background: 'white', border: 'none' }}
//             onClick={handleCreateAuction}
//           >
//             Create Auction
//           </button>
//         </Modal.Footer>
//       </Modal>
//     )
//   }
// }
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap-buttons';
import Web3 from 'web3';
import styled from 'styled-components';
import {
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
  ESCROW_CONTRACT_ABI,
  ESCROW_CONTRACT_ADDRESS,
} from '../../config';

const ModalWrapper = styled(Modal)`
  & .modal-header {
    background: linear-gradient(to right, #6a11cb, #2575fc);
    color: white;
    text-align: center;
    border: none;
  }
  & .modal-title {
    font-size: 2em;
    font-weight: bold;
  }
  & .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  & .form-group {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
  }
  & .form-label {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  & .form-control {
    font-size: 1.2em;
    border: 2px solid #6a11cb;
    border-radius: 5px;
  }
  & .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & button {
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    color: white;
    border: none;
    cursor: pointer;
  }
`;

const handleCreateAuction = async () => {
  let id = window.location.pathname;
  id = id.split('/')[2];
  id = Web3.utils.hexToNumber(id);
  let address;
  if (typeof window.ethereum != 'undefined') {
    const web3 = new Web3(window.ethereum);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    address = accounts[0];
    console.log('address', address);
    let price = document.getElementById('price').value;
    price = web3.utils.toWei(price, 'ether');
    const duration = document.getElementById('duration').value;

    const nftContract = new web3.eth.Contract(
      CONTRACT_ABI,
      CONTRACT_ADDRESS
    );
    let alertFlag1 = false;
    let alertFlag2 = false;
    await nftContract.methods
      .approve(`${ESCROW_CONTRACT_ADDRESS}`, id)
      .send({
        from: address,
        // to: CONTRACT_ADDRESS,
      })
      .on('transactionHash', async function () {
        console.log('Transaction Processing............');
      })
      .on('receipt', async function () {
        console.log('Reciept');
      })
      .on('confirmation', async function () {
        console.log('Transaction Confirmed');
        if (!alertFlag1) {
          alertFlag1 = true;
          alert(`transaction is confirmed, wait for auction confirmation transaction`);
        }
      })
      .on('error', async function () {
        console.log('Error Encountered');
      });

    // const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
    const escrowContract = new web3.eth.Contract(
      ESCROW_CONTRACT_ABI,
      ESCROW_CONTRACT_ADDRESS
    );
    await escrowContract.methods
      .createAuction(id, duration, price)
      .send({
        from: address,
        // gas: 23000
      })
      .on('transactionHash', function () {
        console.log('Transaction Processing............');
      })
      .on('receipt', async function () {
        console.log('Reciept');
      })
      .on('confirmation', async function () {
        console.log('Transaction Confirmed');
        if (!alertFlag2) {
          alertFlag2 = true;
        alert(`Your NFT Has Been Put On Auction!`);
        window.location.reload();
        }
      })
      .on('error', async function () {
        console.log('Error Encountered');
        alert(`Encountered an error`);
        // window.location.reload();
      });
  } else {
    alert(
      "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
      );
    }
  };
  
  export default class AuctionModal extends Component {
    state = { tokenId: '', price: '', duration: '' };
  
    handleChange = (e) => this.setState({ tokenId: e.target.value });
    handleChange2 = (e) => this.setState({ price: e.target.value });
    handleChange3 = (e) => this.setState({ duration: e.target.value });
  
    render() {
      return (
        <ModalWrapper
  centered
  size="lg"
  show={this.props.isOpen}
  onHide={this.props.closeModal}
>
<div style={{
          background: 'linear-gradient(to right, #6e45e2, #88d3ce)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '20px'
        }}>
          <h2 style={{ margin: 0, color: 'white' }}>Auction</h2>
          <button type="button" style={{
            background: 'transparent',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            fontSize: '1.2em'
          }} onClick={this.props.closeModal}>
            <i className="fas fa-times"></i>
          </button>
        </div>
  <Modal.Body>
    <Form.Group>
      <Form.Label>Reserve Price:</Form.Label>
      <Form.Control
        type="text"
        onChange={this.handleChange2}
        value={this.state.price}
        id="price"
        placeholder="in MATIC"
      />
    </Form.Group>
    <Form.Group style={{marginTop:"0.7em"}}>
      <Form.Label>Duration:</Form.Label>
      <Form.Control
        type="text"
        onChange={this.handleChange3}
        value={this.state.duration}
        id="duration"
        placeholder="Hours"
      />
    </Form.Group>
  </Modal.Body>
  <Modal.Footer>
    <button
      type="submit"
      onClick={handleCreateAuction}
    >
      Create Auction
    </button>
  </Modal.Footer>
</ModalWrapper>

      );
    }
  }
  

