import React, { useState, useEffect } from "react";
import "./UserProfile.css";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Box from "@mui/material/Box";
import { db } from "../../firebase";
import axios from "axios";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { selectUserAddress } from "../../features/userSlice";

const NftView = ({ src }) => {
  const [img, setImg] = useState("");

  const loadMedia = async () => {
    try {
      axios.get(`https://sainft.infura-ipfs.io/ipfs/${src}`).then((resp) => {
        // console.log(resp);
        setImg(`https://sainft.infura-ipfs.io/ipfs/${resp.data.url}`);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadMedia();
  }, []);

  return (
    <>
      {img ? (
        <img
          style={{
            height: "350px",
            width: "350px",
            margin: "23px",
            cursor: "pointer",
          }}
          className="card-img-top"
          src={img}
          alt=""
        />
      ) : null}
    </>
  );
};

const UserProfile = () => {
  const history = useHistory();
  const [value, setValue] = useState("1");
  const [userData, setUserData] = useState(null);
  const [nftData, setNftData] = useState(null);
  const [source, setSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("listed");
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  }

  const useraddress = useSelector(selectUserAddress);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchImageObject = async () => {
    try {
      nftData?.forEach((nft) =>
        axios
          .get(`https://sainft.infura-ipfs.io/ipfs/${nft.uri}`)
          .then((resp) => {
            setSource([...source, `https://sainft.infura-ipfs.io/ipfs/${resp.data.url}`]);
          })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUserNFTs = async () => {
    const response = await axios.post(
      "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
      {
        query: `{
            nftentities {
              id
              uri
              owner
              creator {
                id
              }
              auction {
                id
                owner
              }
              sale {
              owner 
              }
            }
          }              
        `,
      }
    );
    // console.log(response.data.data.creators);
    setLoading(true);
    setNftData(response.data.data.nftentities);
    setLoading(false);

    // console.log(nftData);
    return response.data.data.creators;
  };

  useEffect(() => {
    // console.log(nftData);
    // fetchData();
    fetchImageObject();
  }, [nftData]);
  const fetchData = async () => {
    // console.log({ useraddress });
    const response = db.collection("userProfile").doc(useraddress);
    response
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          setUserData(doc.data());
        } else {
          console.log("No such doc");
        }
      })
      .catch((error) => {
        console.log("Error getting doc", error);
      });
  };
  if (useraddress && !userData) {
    fetchData();
    // getUserNFTs();
  }
  if (useraddress && !nftData) {
    getUserNFTs();
  }
  useEffect(() => {}, []);
  return (
    <div style={{ backgroundColor: "white" }} className="userProfile">
      <div className="userProfile__container1">
        <Avatar
          alt=""
          src={userData?.Image || "/static/images/avatar/1.jpg"}
          sx={{ width: 280, height: 280 }}
        />
        <div className="container1__part2">
          <div className="content__1">
            <h3 style={{ color: "black" }}>{userData?.Name || "User Name"}</h3>
            <p>{userData?.Bio || "Bio"}</p>
          </div>

          <div className="content__2">
            <p>{userData?.MetamaskAddress || "Address"}</p>
            {useraddress && (
              <button
                onClick={() => {
                  history.push("/edit-profile");
                }}
                className="edit__button"
              >
                Edit Profile
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <Divider color="white" /> */}
      <div className="userProfile_container2">
        {/* <ul className="mt-5 p-2 netstorm-tab nav nav-tabs" id="nav-tab">
          <li className="list-item">
            <a
              className="active"
              id="nav-home-tab"
              data-toggle="pill"
              href="#nav-home"
            >
              <h5 className="m-0">Listed</h5>
            </a>
          </li>
          <li className="lis">
            <a id="nav-profile-tab" data-toggle="pill" href="#nav-profile">
              <h5 className="m-0">Created</h5>
            </a>
          </li>
          <li className="list-item">
            <a id="nav-profile-tab" data-toggle="pill" href="#nav-profile">
              <h5 className="m-0">Owned</h5>
            </a>
          </li>
        </ul> */}
         <ul
      style={{ display: "flex", textAlign: "center", listStyle: "none" }}
      className="mt-5 p-2 netstorm-tab nav nav-tabs"
      id="nav-tab"
    >
      <li
        style={{
          background: activeTab === "listed" ? "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)" : "none",
          color: activeTab === "listed" ? "white" : "black",
          border: activeTab === "listed" ? "none" : "1px solid #cfc7f8",
          borderRadius: "4px 4px 0 0",
        }}
        onClick={() => handleTabClick("listed")}
      >
        <h5 className="m-0 ml-3 mr-5" style={{color:"black"}}>Listed</h5>
      </li>
      {/* <li
        style={{
          background: activeTab === "created" ? "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)" : "none",
          color: activeTab === "created" ? "white" : "black",
          border: activeTab === "created" ? "none" : "1px solid #cfc7f8",
          borderRadius: "4px 4px 0 0",
        }}
        onClick={() => handleTabClick("created")}
      >
        <h5 className="m-0 mr-5" style={{color:"black"}}>Created</h5>
      </li> */}
      <li
        style={{
          background: activeTab === "owned" ? "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)" : "none",
          color: activeTab === "owned" ? "white" : "black",
          border: activeTab === "owned" ? "none" : "1px solid #cfc7f8",
          borderRadius: "4px 4px 0 0",
        }}
        onClick={() => handleTabClick("owned")}
      >
        <h5 className="m-0 mr-5" style={{color:"black"}}>Owned</h5>
      </li>
    </ul>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home">
            <ul className="p-4 list-unstyled">
              {/* Single Tab List */}
              {nftData ? (
                nftData.map((item, idx) => {
                  return (
                    <div
                      onClick={() => {
                        history.push(`/details/${item.id}`);
                      }}
                      key={`tdo_${idx}`}
                      style={{ display: "contents" }}
                    >
                      {item.auction?.owner == useraddress ||
                      item.sale?.owner == useraddress ? (
                        <NftView src={item.uri} />
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <span>No Listings Yet!</span>
              )}
            </ul>
          </div>
          <div className="tab-pane fade" id="nav-profile">
            <div className="owner-meta d-flex align-items-center mt-3">
              <ul>
                {/* Single Tab List */}
                {nftData ? (
                  nftData.map((item, idx) => {
                    return (
                      <div
                        onClick={() => {
                          history.push(`/details/${item.id}`);
                        }}
                        style={{ display: "contents" }}
                        key={`tdo_${idx}`}
                      >
                        {item.creator.id == useraddress ? (
                          <NftView src={item.uri} />
                        ) : null}
                        {/* <span className="m-0">{item.uri}</span> */}
                      </div>
                    );
                  })
                ) : (
                  <span>No NFTs Created By {useraddress}</span>
                )}
              </ul>
            </div>
          </div>
          <div className="tab-pane fade" id="nav-contact">
            <div className="owner-meta d-flex align-items-center mt-3">
              <ul className="p-4 list-unstyled">
                {/* Single Tab List */}
                {nftData ? (
                  nftData.map((item, idx) => {
                    return (
                      <div
                        onClick={() => {
                          history.push(`/details/${item.id}`);
                        }}
                        key={`tdo_${idx}`}
                        style={{ display: "contents" }}
                      >
                        {item?.owner === useraddress ||
                        item.auction?.owner === useraddress ||
                        item.sale?.owner === useraddress ? (
                          <NftView src={item.uri} />
                        ) : null}
                      </div>
                    );
                  })
                ) : (
                  <span></span>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
