import React, { Component } from "react";
import axios from "axios";
import { db } from "../../firebase";
import { data } from "jquery";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json-1/author";

let nftOwner;
class AuthorProfile extends Component {
  state = {
    socialData: [],
    isLoaded: false,
    data: [],
    error: null,
    userData: null,
  };
  fetchUserData = async () => {
    const response = db.collection("userProfile").doc(nftOwner);
    response
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({ userData: doc.data() });
        } else {
          console.log("No such doc");
        }
      })
      .catch((error) => {
        console.log("Error getting doc", error);
      });
  };
  loadMedia = (src) => {
    var img = new Image();
    img.onerror = () => {
      this.setState({ ...this.state, vid: src });
    };
    img.onload = () => {
      this.setState({ ...this.state, img: src });
    };
    img.src = src;
  };
  fetchImageObject = async (uri) => {
    try {
      axios
        .get(`https://sainft.infura-ipfs.io/ipfs/${uri}`)
        .then((resp) =>
          this.loadMedia(
            `https://sainft.infura-ipfs.io/ipfs/${resp.data.image?.split("ipfs://")[1]}`
          )
        );
    } catch (error) {
      console.log(error);
    }
  };
  getNftOfCreator = async (id) => {
    try {
      const res = await axios.post(
        "https://api.thegraph.com/subgraphs/name/issa-me-sush/sainft",
        {
          query: `{
                    nftentities(where: {creator: "${id}"}) {
                      name
                      description
                      id
                      uri
                      owner
                      creator {
                        id
                      }
                    }
                  }`,
        }
      );

      this.setState(() => ({
        isLoaded: true,
        data: res.data.data.nftentities,
      }));
    } catch (error) {
      this.setState(() => ({ error }));
      console.log(error);
    }
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          socialData: res.data.socialData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
    const path = window.location.pathname;
    const id = path.split("/")[2];
    nftOwner = path.split("/")[2];
    this.fetchUserData(nftOwner);
    this.getNftOfCreator(id);
  }
  render() {
    var { userData } = this.state;
    return (
      <div style={{ marginTop: "50px",backgroundImage: "linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%)", }} className="card no-hover text-center">
        <div className="image-over">
          <img className="card-img-top" src={this.state.data.img} alt="" />
          {/* Author */}
          <div className="author">
            <div className="author-thumb avatar-lg">
              <img
                className="rounded-circle"
                src={userData?.Image || "/img/auction_1.jpg"}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* Card Caption */}
        <div className="card-caption col-12 p-0">
          {/* Card Body */}
          <div className="card-body mt-4">
            <h5 className="mb-3">{userData?.Name}</h5>
            <p className="my-3" style={{ fontSize: "11px" }}>
              {userData?.Bio || ""} {window.location.pathname.split("/")[2]}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthorProfile;
