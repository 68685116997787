import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalTitle from 'react-bootstrap/ModalTitle'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap-buttons'
import Web3 from 'web3'
import {
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
  ESCROW_CONTRACT_ABI,
  ESCROW_CONTRACT_ADDRESS
} from '../../config'

const handleCreateSale = async () => {
  let id = window.location.pathname
  id = id.split('/')[2]
  const token = Web3.utils.hexToNumber(id)
  let address
  if (typeof window.ethereum != 'undefined') {
    const web3 = new Web3(window.ethereum)
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts'
    })
    address = accounts[0]
    console.log('address', address)
    let price = document.getElementById('price').value
    price = web3.utils.toWei(price, 'ether')
    console.log('price and token id', price, token)

    const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
    let alertFlag = false;
    await nftContract.methods
      .approve(ESCROW_CONTRACT_ADDRESS, token)
      .send({
        from: address
        // gas: 23000
      })
      .on('transactionHash', function () {
        console.log('Transaction Processing............')
      })
      .on('receipt', function () {
        console.log('Reciept')
      })
      .on('confirmation', function () {
        console.log('Transaction Confirmed')
        if (!alertFlag) {
          alertFlag = true;
        alert(`transaction confirmed! wait for sale confirmation transaction!`)
        }
      })
      .on('error', async function () {
        console.log('Error Encountered')
      })

    // const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
    const escrowContract = new web3.eth.Contract(
      ESCROW_CONTRACT_ABI,
      ESCROW_CONTRACT_ADDRESS
    )
    let alertFlag2 = false;
    await escrowContract.methods
      .createSale(token, price)
      .send({
        from: address
        // gas: 23000
      })
      .once('transactionHash', function () {
        console.log('Transaction Processing............')
      })
      .once('receipt', function () {
        console.log('Reciept')
      })
      .once('confirmation', function () {
        console.log('Transaction Confirmed')
        if (!alertFlag2) {
          alertFlag2 = true;
        alert(`Your Sale Has Been Started!`)
        window.location.reload();
        }
      })
      .once('error', async function () {
        console.log('Error Encountered')
        alert(`Error Encountered!`)
        window.location.reload();
      })
  } else {
    alert(
      "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
    )
  }
}

export default class SaleModal extends Component {
  state = { tokenId: '', price: '' }

  handleChange = e => this.setState({ tokenId: e.target.value })
  handleChange2 = e => this.setState({ price: e.target.value })
  componentDidUpdate () {
    console.log(this.props.isOpen)
  }

  render () {
    return (
      <Modal
        size='lg'
        centered
        show={this.props.isOpen}
        onHide={this.props.closeModal}
      >
        <div style={{
          background: 'linear-gradient(to right, #6e45e2, #88d3ce)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '20px'
        }}>
          <h2 style={{ margin: 0, color: 'white' }}>Sale</h2>
          <button type="button" style={{
            background: 'transparent',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            fontSize: '1.2em'
          }} onClick={this.props.closeModal}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <Modal.Body>
          <Form.Group style={{ padding: '20px' }}>
            <Form.Label style={{ margin: '10px' }}>Price:</Form.Label>
            <Form.Control
              type='text'
              onChange={this.handleChange2}
              // value={this.state.price}
              id='price'
              placeholder='in MATIC'
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='submit'
            style={{
              background: 'linear-gradient(to right, #6e45e2, #88d3ce)',
              border: 'none',
              color: 'white',
              fontSize: '1em',
              padding: '10px 20px',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
            onClick={handleCreateSale}
          >
            Create Sale
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

// import React, { Component } from 'react'
// import Modal from 'react-bootstrap/Modal'
// import ModalBody from 'react-bootstrap/ModalBody'
// import ModalHeader from 'react-bootstrap/ModalHeader'
// import ModalFooter from 'react-bootstrap/ModalFooter'
// import ModalTitle from 'react-bootstrap/ModalTitle'
// import Form from 'react-bootstrap/Form'
// import Button from 'react-bootstrap-buttons'
// import Web3 from 'web3'
// import {
//   CONTRACT_ABI,
//   CONTRACT_ADDRESS,
//   ESCROW_CONTRACT_ABI,
//   ESCROW_CONTRACT_ADDRESS
// } from '../../config'
// import styled from 'styled-components'

// const handleCreateSale = async () => {
//   let id = window.location.pathname
//   id = id.split('/')[2]
//   const token = Web3.utils.hexToNumber(id)
//   let address
//   if (typeof window.ethereum != 'undefined') {
//     const web3 = new Web3(window.ethereum)
//     const accounts = await window.ethereum.request({
//       method: 'eth_requestAccounts'
//     })
//     address = accounts[0]
//     console.log('address', address)
//     let price = document.getElementById('price').value
//     price = web3.utils.toWei(price, 'ether')
//     console.log('price and token id', price, token)

//     const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
//     await nftContract.methods
//       .approve(ESCROW_CONTRACT_ADDRESS, token)
//       .send({
//         from: address
//         // gas: 23000
//       })
//       .on('transactionHash', function () {
//         console.log('Transaction Processing............')
//       })
//       .on('receipt', function () {
//         console.log('Reciept')
//       })
//       .on('confirmation', function () {
//         console.log('Transaction Confirmed')
//       })
//       .on('error', async function () {
//         console.log('Error Encountered')
//       })

//     // const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
//     const escrowContract = new web3.eth.Contract(
//       ESCROW_CONTRACT_ABI,
//       ESCROW_CONTRACT_ADDRESS
//     )
//     await escrowContract.methods
//       .createSale(token, price)
//       .send({
//         from: address
//         // gas: 23000
//       })
//       .once('transactionHash', function () {
//         console.log('Transaction Processing............')
//       })
//       .once('receipt', function () {
//         console.log('Reciept')
//       })
//       .once('confirmation', function () {
//         console.log('Transaction Confirmed')
//         alert(`Your Sale Has Been Started!`)
//         window.location.reload();
//       })
//       .once('error', async function () {
//         console.log('Error Encountered')
//         alert(`Error Encountered!`)
//         window.location.reload();
//       })
//   } else {
//     alert(
//       "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
//     )
//   }
// }

// const StyledModal = styled(Modal)`
//   background: linear-gradient(to bottom, #00b8d4, #0052cc);
//   color: white;
//   display: flex;
//   align-items: center;
// `

// const StyledButton = styled(Button)`
//   background: linear-gradient(to right, #ff5f6d, #ffc371);
//   color: white;
//   border-radius: 20px;
//   font-size: 14px;
//   font-weight: bold;
//   padding: 10px 20px;

//   &:hover {
//     background: linear-gradient(to right, #ffc371, #ff5f6d);
//     cursor: pointer;
//   }
// `

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 20px;
//   position: absolute;
//   top: 10px;
//   right: 10px;

//   &::before {
//     content: "\f00d";
//     font-family: 'Font Awesome 5 Free';
//   }
// `

// export default class SaleModal extends Component {
//   state = { tokenId: '', price: '' }

//   handleChange = e => this.setState({ tokenId: e.target.value })
//   handleChange2 = e => this.setState({ price: e.target.value })
//   componentDidUpdate () {
//     console.log(this.props.isOpen)
//   }

//   render () {
//     return (
//       <StyledModal
//         size='lg'
//         show={this.props.isOpen}
//         onHide={this.props.closeModal}
//         aria-labelledby='example-modal-sizes-title-lg'
//       >
//         <Modal.Header closeButton>
//           <CloseButton onClick={this.props.closeModal} />
//           <Modal.Title id='example-modal-sizes-title-lg'>
//             Sale
//           </Modal.Title>
//         </Modal.Header>
//         <ModalBody>
//           <Form>
            
//             <Form.Group controlId='formBasicPassword'>
//               <Form.Label>Price (in ETH)</Form.Label>
//               <Form.Control
//                 type='text'
//                 placeholder='Enter Price (in ETH)'
//                 value={this.state.price}
//                 onChange={this.handleChange2}
//               />
//             </Form.Group>
//           </Form>
//         </ModalBody>
//         <ModalFooter>
//           <StyledButton onClick={handleCreateSale}>Start Sale</StyledButton>
//         </ModalFooter>
//       </StyledModal>
//     )
//   }
// }


       
// import React, { Component } from 'react'
// import Modal from 'react-bootstrap/Modal'
// import ModalBody from 'react-bootstrap/ModalBody'
// import ModalHeader from 'react-bootstrap/ModalHeader'
// import ModalFooter from 'react-bootstrap/ModalFooter'
// import ModalTitle from 'react-bootstrap/ModalTitle'
// import Form from 'react-bootstrap/Form'
// import Button from 'react-bootstrap-buttons'
// import Web3 from 'web3'
// import styled from 'styled-components'
// import {
//   CONTRACT_ABI,
//   CONTRACT_ADDRESS,
//   ESCROW_CONTRACT_ABI,
//   ESCROW_CONTRACT_ADDRESS
// } from '../../config'

// const ModalWrapper = styled(Modal)`
//   & .modal-header {
//     background: linear-gradient(to right, #e66465, #9198e5);
//     text-align: center;
//     font-size: 24px;
//     font-weight: bold;
//     color: white;
//   }

//   & .modal-body {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   & .modal-footer {
//     display: flex;
//     justify-content: center;
//   }

//   & .modal-title {
//     font-size: 20px;
//   }

//   & .btn {
//     background-color: #9198e5;
//     color: white;
//     font-size: 16px;
//     border-radius: 5px;
//     &:hover {
//       background-color: #6b72e7;
//     }
//   }
// `

// const handleCreateSale = async () => {
//   let id = window.location.pathname
//   id = id.split('/')[2]
//   const token = Web3.utils.hexToNumber(id)
//   let address
//   if (typeof window.ethereum != 'undefined') {
//     const web3 = new Web3(window.ethereum)
//     const accounts = await window.ethereum.request({
//       method: 'eth_requestAccounts'
//     })
//     address = accounts[0]
//     console.log('address', address)
//     let price = document.getElementById('price').value
//     price = web3.utils.toWei(price, 'ether')
//     console.log('price and token id', price, token)

//     const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
//     await nftContract.methods
//       .approve(ESCROW_CONTRACT_ADDRESS, token)
//       .send({
//         from: address
//         // gas: 23000
//       })
//       .on('transactionHash', function () {
//         console.log('Transaction Processing............')
//       })
//       .on('receipt', function () {
//         console.log('Reciept')
//       })
//       .on('confirmation', function () {
//         console.log('Transaction Confirmed')
//       })
//       .on('error', async function () {
//         console.log('Error Encountered')
//       })

//     // const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
//     const escrowContract = new web3.eth.Contract(
//       ESCROW_CONTRACT_ABI,
//       ESCROW_CONTRACT_ADDRESS
//     )
//     await escrowContract.methods
//       .createSale(token, price)
//       .send({
//         from: address
//         // gas: 23000
//       })
//       .once('transactionHash', function () {
//         console.log('Transaction Processing............')
//       })
//       .once('receipt', function () {
//         console.log('Reciept')
//       })
//       .once('confirmation', function () {
//         console.log('Transaction Confirmed')
//         alert(`Your Sale Has Been Started!`)
//         window.location.reload();
//       })
//       .once('error', async function () {
//         console.log('Error Encountered')
//         alert(`Error Encountered!`)
//         window.location.reload();
//       })
//   } else {
//     alert(
//       "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
//     )
//   }
// }

// export default class SaleModal extends Component {
//   state = { tokenId: '', price: '' }

//   handleChange = e => this.setState({ tokenId: e.target.value })
//   handleChange2 = e => this.setState({ price: e.target.value })
//   componentDidUpdate () {
//     console.log(this.props.isOpen)
//   }

//   render () {
//     return (
//       <ModalWrapper show={this.props.isOpen} onHide={this.props.closeModal}>
//       <ModalHeader closeButton>
//         <ModalTitle>Sale</ModalTitle>
//       </ModalHeader>
//       <ModalBody>
//         <Form>
//           <Form.Group controlId='price'>
//             <Form.Label>Price (in ETH)</Form.Label>
//             <Form.Control type='text' placeholder='Enter Price' />
//           </Form.Group>
//         </Form>
//       </ModalBody>
//       <ModalFooter>
//         <Button onClick={handleCreateSale}>Start Sale</Button>
//       </ModalFooter>
//     </ModalWrapper>
//     )
//   }
// }


